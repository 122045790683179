import artical_five from "./artical_five";
import article_distance_reiki from "./article_distance_reiki";
import article_reiki_auras from "./article_reiki_auras";
import article_reiki_meditation from "./article_reiki_meditation";
import article_uses_of_reiki from "./article_uses_of_reiki";

export const allArticleData = [
  artical_five,
  article_distance_reiki,
  article_reiki_auras,
  article_reiki_meditation,
  article_uses_of_reiki,
];

export const allArticleDataTypes = [
  {
    type: "article",
    name: "精选文章",
    course: allArticleData,
  },
];

export const findArticleById = (id = []) => {
  let temp = [];
  allArticleData.forEach((item) => {
    if (id.includes(item.id)) {
      temp.push(item);
    }
  });
  return temp;
};
