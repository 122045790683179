<template>
  <div class="navItemWrap" @mouseleave="navItemWrapLeave">
    <div
      v-for="(courseDataItem, index) in dataList"
      :key="index"
      class="courseDataItem"
    >
      <p class="name">{{ courseDataItem.name }}</p>
      <ul>
        <li
          v-for="item in courseDataItem.course"
          :key="item.id"
          @click="itemClick(courseDataItem.type, item)"
        >
          {{ item.title }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showSubMenu: false,
    };
  },
  props: {
    dataList: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    navmouseenter() {
      this.showSubMenu = true;
    },
    navItemWrapLeave() {
      this.$emit("navItemWrapLeave");
    },
    itemClick(type, item) {
      const { id } = item;
      this.$router.push({
        path:
          type === "course"
            ? `/course/courseDetail/${id}`
            : `/resources/resourceDetail/${id}`,
      });
      this.$emit("navItemWrapLeave");
    },
  },
};
</script>

<style lang="less" scoped>
.navItemWrap {
  width: 100%;
  padding: 16px 16.7%;
  position: absolute;
  left: 0;
  right: 0;
  top: 63px;
  background: #fff;
  z-index: 99;
  display: flex;
  flex-wrap: wrap;
  .courseDataItem {
    width: 280px;
    margin-right: 20px;
    p.name {
      height: 44px;
      line-height: 44px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: #08101e;
      text-align: left;
      border-bottom: 1px solid #d9ad6a;
    }
    ul {
      li {
        width: 100%;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #333333;
        line-height: 32px;
        text-align: left;
        font-style: normal;
        cursor: pointer;
      }
    }
  }
}
</style>
