export default {
  img: require("@/assets/images/banner.png"),
  banner: require("@/assets/images/banner.png"),
  title: "古埃及灵气课",
  introduction: "古埃及灵气与埃及众神有着密切的关联，拉、欧西里斯、艾西斯、阿努比斯、荷鲁斯等等。伴随着点化，你可以链接古埃及众神的力量，众神会在需要的时候加入灵气疗癒的过程。SSR灵气系统直接与ALL  LOVE连结。",
  id: "course_egyptian",
  auth: "华人灵气研究协会",
  type: "ccourse",
  relate: ["course_egyptian"],
  time: "2014-01-01",
  reikiEffect: [
    "链接古埃及众神的力量",
    "直接与ALL  LOVE连结",
    "非常的温柔，坚韧，沉静、内敛、有力",
    "减轻积存身体的负面能量",
    "改善睡眠质量、减轻神经紧张",
    "减轻内心痛楚、烦躁不安、恐惧感",
    "增强自觉、直觉与内在洞察力",
    "促进个人成长、提升灵性修为",
  ],
  content: `
  1.古埃及人的文明是人类历史上伟大和最先进的文明之一。他们给我们留下了充满神秘主义和智慧的丰富遗产。

  SSR古埃灵气是由Sekhem、Seichim、Reik,三种疗愈能量组合而成的神圣疗愈系統。

   Sekhem：古埃及-力量
   
   Seichim：古埃及-光，古老的一个疗愈系統；
   
   Reiki：日语-灵气，宇宙中的生命力量；
   
   Sekhem-Seichim-Reiki指来自古埃及的疗愈之光，神圣系统，也被称呼为古埃及灵气。

2.这个体系有三股能量，具有不同的作用，担任不同的角色。
   
   Sekhem：解除自我障碍，重新连接更高层次的能量链接，与古埃及众神建立连接
   
   Seichim：维持身体与Ling魂之间的平衡，维持人体内阴/阳平衡
   
   Reiki:  将宇宙中的生命能量引入到人的身体与灵魂之中
 
   这三股能量，有时候会单独助力人们身心成长，有时候会结合，当结合时，能量是成指数级递增。
   

<h2>课程介绍</h2>

<h2>Part One</h2>

1.古埃及人如何看待“神与女神”
2.代表五种元素的“神与女神”
3.存在的四个层面以及对应的“神与女神”
4.“SEKHMET”的含义以及解读【治愈女神；破坏与保护女神；天狼星的与关联】 
5.“SEKHMET”的原型、偏好和头衔
6.平衡身体五种元素的呼吸技巧与手印学习
7.更多...

<h2>Part Two</h2>

1.6个灵气符号的学习，守护者，心符，和平之星，RA的永恒活水，蓝珍珠的智慧，神圣指示符号
2.古埃及瑜伽系统“Smai Taui”介绍
3.如何执行强大的“SEKHMET 火-水呼吸”
4.如何进行面对面以及远程疗Yu【遥治】
5.使用SSR为任何物体充电
6.学习运用SSR灵气处理心灵，情绪和身体，家族DNA等各类主题
7.更多...

<h2>Part Three</h2>

1.古埃及生命之树的介绍
2.“Cht n Ankh”&"KaBaLah" 
3.与古埃及生命之树链接，以及如何提升自己
4.’Hekau‘咒语对于七脉轮的疗愈
5.古埃及大祭司‘Sahu 光之桥‘和’Sahu 疗愈’
6.‘Ab’激活
7.能量魔法文本学习
8.更多...


<h2>上课形式</h2>

1.注：知识点部分会提供录播，可反复听
1.点化部分
2.点化方式：腾讯会议/Zoom ，实时参与如果不可以实时参与，需提供个人相关信息
3.相关信息：姓名，出生日期年月日或者照片       
4.课程价格：688元
5.早鸟价：499元
6.复训价格：99元
7.课程时长：120分钟 [包括点化时间，重点内容介绍,不包括录播内容时间]
8.下期课程时间：咨询客服


  `,
};