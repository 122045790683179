/* eslint-disable no-unused-vars */
import Vue from "vue";
import VueRouter from "vue-router";
import home from "../views/home";
import store from "@/store";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: home,
  },
  {
    path: "/resources",
    name: "resources",
    component: () => import("@/views/resources/index.vue"),
  },
  {
    path: "/resources/resourceDetail/:id",
    name: "resourceDetail",
    component: () => import("@/views/resources/resourceDetail.vue"),
  },
  {
    path: "/course",
    name: "course",
    component: () => import("@/views/course/index.vue"),
  },
  {
    path: "/course/courseDetail/:id",
    name: "courseDetail",
    component: () => import("@/views/course/courseDetail.vue"),
  },
  {
    path: "/memberShip",
    name: "memberShip",
    component: () => import("@/views/memberShip/index.vue"),
  },
  {
    path: "/personalCenter",
    name: "personalCenter",
    meta: {
      requiresAuth: true,
    },
    component: () => import("@/views/personalCenter/index.vue"),
  },
  {
    path: "/shopCar",
    name: "shopCar",
    meta: {
      requiresAuth: true,
    },
    component: () => import("@/views/shopCar/index.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

const isAuthenticated = () => {
  return store.getters.token; // 返回true表示已登录，false则未登录
};

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth && !isAuthenticated()) {
    // to.meta.requiresAuth表示该路由需要登录才能访问，!isAuthenticated()表示当前用户没有登录
    next("/"); // 重定向到登录页面或者其他指定的页面
    store.commit("setIsShowLogin", true);
  } else {
    next(); // 不需要登录就直接放行
  }
});

router.afterEach(() => {
  // 使用Vue.nextTick确保DOM已经更新
  Vue.nextTick(() => {
    // 滚动到页面顶部
    document.getElementById("content").scrollTo(0, 0);
  });
});
export default router;
