<!-- eslint-disable vue/no-useless-template-attributes -->
<!-- eslint-disable vue/no-use-v-if-with-v-for -->
<template>
  <div class="banner">
    <transition name="banner">
      <template v-for="(item, index) in bannerList">
        <div
          class="img"
          :key="index"
          v-if="num === index"
          @mouseover="mouseOver"
          @mouseleave="mouseLeave"
          @mousedown="mouseDown"
        >
          <img class="bg" v-if="item.src" :src="item.src" />
          <div class="inner" v-if="item.innerImg">
            <img :src="item.innerImg" />
            <div class="textCont">
              <p class="title">{{ item.title }}</p>
              <p class="introduction">{{ item.introduction }}</p>
            </div>
          </div>
        </div>
      </template>
    </transition>
    <div class="dort" v-if="bannerList.length > 1">
      <ul>
        <li
          v-for="(item, index) in bannerList"
          :key="index"
          :class="{ active: index === num }"
          @click="changeSwipper(index)"
        ></li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      num: 0, // 默认显示第几个
      animateTime: 3000, // 要和 css 的过渡一致
      timer: null,
      canplay: true,
    };
  },
  props: {
    bannerList: {
      typeof: Array,
      default: () => [],
    },
  },
  mounted() {
    if (this.bannerList.length > 1) {
      this.play(); // 初始的时候加载
    }
  },
  methods: {
    mouseOver() {
      if (this.bannerList.length > 1) {
        this.canplay = false; // 初始的时候加载
      }
    },
    mouseLeave() {
      if (this.bannerList.length > 1) {
        this.canplay = true; // 初始的时候加载
      }
    },
    mouseDown() {},
    changeSwipper(index) {
      this.num = index;
      clearInterval(this.timer);
      this.play();
    },
    play() {
      // 设置定时器，让banner显示隐藏
      this.timer = setInterval(() => {
        if (!this.canplay) {
          return;
        }
        this.num++;
        if (this.num == this.bannerList.length) {
          this.num = 0;
        }
      }, this.animateTime);
    },
  },
  beforeDestroy() {
    this.timer && clearInterval(this.timer);
  },
};
</script>
<style lang="less" scoped>
.banner {
  width: 100%;
  padding-bottom: 31%;
  background-color: #f0f0f0;
  position: relative;
  overflow: hidden;
  border-bottom: 8px solid #d9ad6a;
  .dort {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    ul {
      display: flex;
      align-items: flex-end;
      li {
        width: 18px;
        margin-right: 4px;
        height: 4px;
        background: #ffffff;
        box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.3);
        opacity: 0.5;
        position: relative;
        cursor: pointer;
        &.active {
          height: 8px;
          opacity: 1;
          &::after {
            display: block;
            content: "";
            position: absolute;
            left: 0;
            top: -5px;
            width: 100%;
            width: 18px;
            height: 2px;
            background: #ffffff;
            box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.3);
          }
        }
      }
    }
  }
}

.banner .img {
  width: 100%;
  position: absolute;
  top: 0;
  height: 100%;
  .bg {
    width: 100%;
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
  }
  .inner {
    display: block;
    position: absolute;
    // left: 16%;
    // right: 16%;
    bottom: 0;
    top: -8px;
    z-index: 1;
    img {
      width: 100%;
    }
    .textCont {
      display: block;
      position: absolute;
      left: 18%;
      right: 18%;
      bottom: 0;
      top: 0;
      z-index: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .title {
        font-size: 54px;
        font-weight: 500;
        color: #ffffff;
        line-height: 81px;
        margin-bottom: 20px;
        font-weight: 400;
      }
      .introduction {
        font-size: 20px;
        font-weight: 675;
        color: #ffffff;
        line-height: 30px;
        width: 500px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        text-align: center;
        font-weight: 400;
      }
    }
  }
}

.banner-enter-active,
.banner-leave-active {
  transition: all 2s ease-in-out;
}

.banner-enter {
  opacity: 0;
}
.banner-enter-to {
  opacity: 1;
}

.banner-leave {
  opacity: 1;
}
.banner-leave-to {
  opacity: 0;
}
</style>
