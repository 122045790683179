<template>
  <div class="reiki">
    <img src="@/assets/images/reiki.png" />
    <div class="title">
      <img src="@/assets/images/title_left.png" alt="" />
      <span>{{ $t("reikiEffect") }}</span>
      <img src="@/assets/images/title_right.png" alt="" />
    </div>
    <div class="listItem">
      <ul>
        <li v-for="(item, index) in rikiList" :key="index" :style="item.style">
          {{ item.text }}
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      rikiList: [
        {
          text: "舒缓长期疲乏症状，舒解痛楚",
          style: {
            left: "22%",
            top: "38%",
          },
        },
        {
          text: "体现爱、慈悲和万物合一的境界",
          style: {
            left: "22%",
            top: "51%",
          },
        },
        {
          text: "增强自觉、直觉与内在洞察力",
          style: {
            left: "22%",
            top: "65%",
          },
        },
        {
          text: "提高身体自愈能力",
          style: {
            left: "22%",
            top: "78%",
          },
        },
        {
          text: "人生观变得乐观、正向积极",
          style: {
            right: "22%",
            top: "38%",
          },
        },
        {
          text: "减轻积存身体的负面能量",
          style: {
            right: "22%",
            top: "51%",
          },
        },
        {
          text: "增强自信心",
          style: {
            right: "22%",
            top: "65%",
          },
        },
        {
          text: "提高意志力",
          style: {
            right: "22%",
            top: "78%",
          },
        },
      ],
    };
  },
};
</script>
<style lang="less" scoped>
.reiki {
  position: relative;
  img {
    width: 100%;
  }
  .title {
    position: absolute;
    left: 0;
    right: 0;
    top: 22%;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 80px;
    }
    span {
      padding: 0 20px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 300;
      font-size: 28px;
      color: #08101e;
      line-height: 40px;
      text-align: center;
      font-style: normal;
    }
  }
  .listItem {
    ul li {
      position: absolute;
      font-size: 16px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      color: #d9ad6a;
      line-height: 22px;
    }
  }
}
</style>
