export default {
  img: require("@/assets/images/banner1.png"),
  banner: require("@/assets/images/banner1.png"),
  title: "运用灵气来观察光环和前世",
  introduction: "",
  id: "article_reiki_auras",
  auth: "华人灵气研究协会",
  type: "article",
  relate: ["article_three", "course_two"],
  time: "2014-01-01",
  content: `
  ![alt text](auras.jpg)

下面是一种运用灵气来观察光环和前世的简单方法。具体步骤如下:

步骤1-5

1.找一个搭档，面对面坐着，距离大约180cm-300cm。
2.让对方闭上眼睛，深呼吸，放松，双手放在腿上。
3.给自己的双手打灵气符号，将双手放在腿上，开始给自己输送灵气。
4.几分钟（当你觉察到你准备好了），开始用柔和的眼神注视你的搭档。
5.用眼睛输送灵气给你的搭档。

步骤6-10
6.当你这样做的时候，你可能会进入一种轻度恍惚的状态。允许这一切的发生。
7.继续凝视对方，你可能会开始觉察到对方周围发生的变化。可能开始出现雾气，或者正在逐渐变黑，直到你完全看不见对方面部为止。发生这种情况时，继续放松保持镇定继续凝视对方。
8.当你继续时，迷雾或黑暗会清除，您可能会看到对方的外观有所不同。这很可能是他们前世的样子。
9.您可能还会在头部周围看到白色的薄雾，或者可能看到其他颜色。这是光环。
10.许多人还看到对方旁边有另一个人的形象或一盏灯。这也可能是他们过去生活中的样子，也可能是他们的精神向导之一。

这个小技巧非常有效，很多人在第一次尝试时就得到了积极的结果，但是技巧是需要练习的，且在练习中，双方都会得到疗愈，试一试吧。
  `,
};
