export default {
  img: require("@/assets/images/banner1.png"),
  banner: require("@/assets/images/banner1.png"),
  title: "灵气五戒",
  introduction: "",
  id: "artical_five",
  auth: "华人灵气研究协会",
  type: "ccourse",
  relate: [""],
  time: "2014-01-01",
  content: `
  ![alt text](five.jpg)
  
  灵气的创始人臼井先生提出了五项原则，是为所有灵气实践者和爱好者建议的指导方针，并称之为“招福之秘法，万病之良药”。
  每个原则都以“就在今天”开始，专注于我们有能力改变的(精神状态)，而不是过去或未来引起的担忧和愤怒。这些原则将我们的意识带到当下，让我们更加临在。
  我把这些原则挂在墙上作为提醒。它们如此的简单纯粹，确让我感到一种平和的能量，特别是在艰难的日子里。臼井先生也鼓励我们在进行灵气练习以及日常生活中使用这些原则，进而促进健康、有爱的生活方式。
  
  <h2>1. 就在今天，我不生气</h2>

  愤怒通常是压力和焦虑的主要症状之一。当然，有时只是一种消极情绪打压着我们，如果我们学会控制我们的愤怒，管理我们的压力时，我们也学会了控制其他情绪。
  一些负面情绪，比如愤怒或嫉妒，是一种非常自然的情绪——并不代表你不是好人。只是因为这些情绪改变了我们的行为，阻碍了我们和爱与光的链接。
  比如在一个普通的上班的清晨，你碰到了一个暴躁的司机，你可能会生气。如果你选择深呼吸，放松，原谅，让它过去呢，又会有什么更多的可能性呢? 你是如此的扩展；如果你选择了愤怒，甚至想'攻击'他等等，而此时，你会发现你是收缩的。
  
  <h2>2. 就在今天，我不担心</h2>

  压力是负能量的第一来源，它有巨大的能量来贬低我们的灵魂和精神，把我们变成不愉快(不健康)的人。压力会把我们变成粗鲁、愤怒、急躁的人——破坏思想、身体和灵魂之间的平衡。
  把这句准则应用到你的生活中会提醒你，大多数时候，我们感到的压力是不值得的，用积极的态度对待每一天会更好，下定决心保持冷静。
  遵循这一原则是自我提高的关键——随着时间的推移，你会发现你的心情有了明显的改善，生活也会变得更加愉快。所以，就在今天，相信精神、源头、上帝(或者你自己!)，要知道一切都会好起来的，一切如是。
  
  <h2>3. 就在今天，我心存感激</h2>

  我们总是要求更多，却只看到我们没有的。让我们用一天的时间来感谢我们所拥有的一切——比如一份工作，一辆可以载你去任何地方的车，一个可以遮风挡雨的房子，健康的身体和一个无条件爱你、支持你的家庭。当我们感激我们所拥有的东西时，我们会吸引更多的好东西。吸引力法则表明，喜欢吸引喜欢，缺乏吸引缺乏，所以保持积极的心态，对你所拥有的心怀感激。
  
  <h2>4. 就在今天，我认真工作</h2>

  认真地工作能给你的生活带来更多的目标和意义。当你认真而有目的地工作时，一天结束时你会对自己感恩，对工作充满成就感。
  
  <h2>5. 就在今天，我与人为善</h2>
  
  你付出什么，就会得到十倍的回报。对每个人都要友善、有爱心和关心，即使对方不是你最喜欢的人。他们都值得爱和友善。
  这个概念同样适用于比我们小的生物。所有的生命都应该受到尊重。试着对动物好一点，看看感觉如何。你可以在公园里喂鸟，或者向当地的动物收容所捐赠食物。你不必花费太多精力。
  在一天结束的时候，我们会因为给别人的一天带来一些光明和爱而感觉更好，即使只是片刻。
  尽管每天都遵循这五项原则似乎有点难，但你可以从今天开始尝试其中的一项。我保证，在一天结束的时候，你会更快乐。
  你也可以每天早上和睡前，双手合十，将这灵气五戒念几遍。用心即可，大声念，默念都可以。
  
  如果你想知道更多灵气相关知识或者咨询灵气课程，欢迎添加微信咨询。`,
};
