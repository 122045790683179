import course_angel from "./course_angel";
import course_candle from "./course_candle";
import course_animal from "./course_animal";
import course_crystal from "./course_crystal";
import course_egyptian from "./course_egyptian";
import course_green_tara from "./course_green_tara";
import course_reiki_1_2_3 from "./course_reiki_1_2_3";
import course_karuna from "./course_karuna";
import course_reiki_master from "./course_reiki_master";
import course_shamballa from "./course_shamballa";
import course_violet from "./course_violet";
import course_kundalini from "./course_kundalini";
import course_money from "./course_money";

//小品种灵气课程
export const smallVarityCourseData = [
  course_angel,
  course_candle,
  course_animal,
  course_crystal,
  course_egyptian,
  course_green_tara,
  course_reiki_1_2_3,
  course_karuna,
  course_reiki_master,
  course_shamballa,
  course_violet,
  course_kundalini,
  course_money,
];

const icrtCourse = [
  course_shamballa,
  course_violet,
  course_kundalini,
  course_money,
];

//普通课程
export const commonCourse = [course_angel, course_crystal, course_egyptian];

export const allCourseData = [...smallVarityCourseData, ...commonCourse];

export const allCourseDataTypes = [
  {
    type: "course",
    name: "小品种灵气课程",
    course: smallVarityCourseData,
  },
  {
    type: "course",
    name: "ICRT认证灵气课程",
    course: icrtCourse,
  },
];

export const findCourseById = (id = []) => {
  let temp = [];
  allCourseData.forEach((item) => {
    if (id.includes(item.id)) {
      temp.push(item);
    }
  });
  return temp;
};
