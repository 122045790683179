export default {
  img: require("@/assets/images/banner.png"),
  banner: require("@/assets/images/banner.png"),
  title: "金钱灵气课",
  introduction: "当我们调用金钱灵气时，可以协助我们清理关于金钱的卡点，逐渐提升自己的金钱能量，协助金钱自由流动，协助，我们从金钱的逆向循环，转化为正向循环。",
  id: "course_money",
  auth: "华人灵气研究协会",
  type: "ccourse",
  relate: ["course_"],
  time: "2014-01-01",
  reikiEffect: [
    "清理个人财富卡点，协助提升财富能量",
    "清理群体，家族财富卡点",
    "清理关于金钱的负面观念",
    "清理脉轮堵塞",
    "清理内在匮乏，提升内在丰盛",
    "提升空间金钱能量",
  ],
  content: `
你有金钱方面的困扰吗？

你对金钱有观点，有执念吗？

你认为自己不值得丰盛吗？

你愿意接收来自宇宙的贡献吗？

你喜欢金钱吗？你想拥有金钱吗？

你允许自己超越自己，超越家族吗？


金钱灵气是一种关于丰盛的高频震动，一种宇宙生命能量。

当我们调用金钱灵气时，可以协助我们清理关于金钱的卡点，逐渐提升自己的金钱能量，协助金钱自由流动，协助，我们从金钱的逆向循环，转化为正向循环。

任何人都可以选择金钱灵气，只要你愿意


<h2>金钱符号</h2>

每个符号有自己的含义，符号的学习与运用，可以从不同方位，改善金钱能量。


<h2>金钱能量产品</h2>

通过结合植物，水晶与金钱的能量，我们可以通过制作金钱灵气相关能量产品。

金钱灵气周边，包括金钱油，金钱喷雾，金钱袋子...可以协助改善个人金钱，灵性金钱，群体金钱，空间金钱卡点，提升金钱能量。


<h2>课程介绍</h2>

金钱灵气一阶

1、金钱灵气基础知识介绍

2、金钱灵气与能量系统

3、金钱能量的两种运作模式

4、金钱能量的分类，运作原理，如何调用

5、1阶金钱灵气符号

6、制作金钱磁铁石与金钱灵气宝盒

7、更多...



金钱灵气二阶

1、2阶金钱灵气符号3个

2、金钱财富冥想

3、如何为自己/他人进行面对面疗愈

4、如何为他人进行远程金钱疗愈

5、增加金钱小技巧

6、如何运用金钱灵气给物品祝福

7、如何运用金钱灵气给空间祝福

8、更多...







金钱灵气三阶

1、3阶金钱灵气符号3个

2、金钱灵气与业力【个人&家族&地球】

3、金钱与植物，金钱与水晶...

4、金钱灵气相关手作

5、制作金钱灵气油

6、制作金钱灵气喷雾

7、制作金钱袋子

8、如何定制金钱灵气个人周边

9、更多..


注：知识点部分会提供PPT语言录播，可反复听

<h2>上课形式</h2>

注：知识点部分会提供录播，可反复听

点化部分

点化方式：腾讯会议/Zoom ，实时参与如果不可以实时参与，需提供个人相关信息

相关信息：姓名，出生日期年月日或者照片       

课程价格：1688元

特价期：688元

复训价格：99元

课程时长：120分钟 [包括点化时间，重点内容介绍,不包括录播内容时间]

下期课程时间：咨询客服
  `,
};