export default {
  img: require("@/assets/images/banner.png"),
  banner: require("@/assets/images/banner.png"),
  title: "香巴拉灵气课",
  introduction: "整个体系学习完成后，你将获得无条件的爱，以及高等智慧等，形成一套体系。",
  id: "course_shamballa",
  auth: "华人灵气研究协会",
  type: "ccourse",
  relate: ["course_"],
  time: "2014-01-01",
  reikiEffect: [
    "学会可以下载任何美好的能量",
    "减轻积存身体的负面能量",
    "改善睡眠质量、减轻神经紧张",
    "减轻内心痛楚、烦躁不安、恐惧感",
    "增强自觉、直觉与内在洞察力",
    "促进个人成长、提升灵性修为",
  ],
  content: `
1.香巴拉灵气是一种能量疗法。这是臼井灵气的扩展。香巴拉能量全称是香巴拉多维愈合能量。整个系统，我们会介绍，从胚胎，幼儿，少年，青年，老年，死亡，整个生命阶段，人类七个层面的如何发展，香巴拉如何介入处理，协调平衡。

2.香巴拉1-4阶会学习，如何进行面对面，远程的疗愈，以及如何教学香巴拉灵气，如何下载其他灵气能量。整个体系学习完成后，你将获得无条件的爱，以及高等智慧等，形成一套体系。

<h2>课程介绍</h2>

<h2>香巴拉一阶</h2>

1.灵气的介绍，灵气的五大守则
2.香巴拉灵气历史，起源，含义，创始人等介绍
3.人类七个主要能量中心(七脉轮)
4.气场等学习
5.点化/Attunement是什么？点化一阶香巴拉符号
6.手位法介绍，扫描介绍
7.疗愈/Healing是什么？ 如何给自己和他人进行完整的一阶疗愈
8.介绍人的不同时期，灵气如何协助
9.更多...


<h2>香巴拉二阶</h2>

1.对古代香巴拉灵气疗法符号的深入研究
2.点化二阶香巴拉灵气符号，每个符号运用
3.如何进行远距离疗愈
4.如何进行集体个案
5.如何运用灵气进行主题疗愈
6.如何运用灵气净化、加持物品
7.如何运用灵气进行空间净化与祝福
8.高维知识介绍以及运用
9.高等团队介绍以及运用
10.DNA的激活以及启动
11.更多.



<h2>香巴拉三阶</h2>

1.如何给他人进行1-2阶的点化
2.进行香巴拉3阶点化
3.学习3阶符号以及运用
4.介绍集体人类意识&爱的能量
5.默哈玛相关介绍
6.高等团队介绍
7.高阶香巴拉技巧学习
8.如何进行1-2阶的教学
9.更多...


<h2>香巴拉四阶</h2>

1.如何活出自己的价值和力量
2.DNA的深入激活以及启动
3.学习如何给他人进行1-4阶的点化
4.学习如何进行香巴拉教学
5.学习4阶香巴拉符号
6.高等团队相关知识点
7.如何发展提升自己的香巴拉能量
8.能量球相关知识点
9.导师阶香巴拉技巧学习
10.更多...



<h2>上课形式</h2>

录播+直播结合

录播部分：

1、录播部分包括所有与香巴拉课程知识点；

2、后续有香巴拉补充，免费更新



直播部分：

1、重点知识介绍

2、课堂点化

3、互相练习

4、答疑等

直播部分，会有4个晚上，每阶一个晚上，本课程与其他小品种不同，是小班授课，可报名后进群协调时间
注意：小班授课，报名后可进群协调时间



连报4阶，有纸质课本+证书

成为导师后，后续开课，会提供协助



课程价格：

一阶    999元         588元【50个特惠名额】

二阶    1111元         666元【50个特惠名额】

三阶    1888元        1111元【50个特惠名额】

四阶    2222元       1555元【50个特惠名额】

四阶连报  5200元   3088元【50个特惠名额】  2988元【两人同行价】 

 `,
};