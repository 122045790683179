<template>
  <div>
    <div class="bottomWrap">
      <img src="@/assets/images/footer_1.png" alt="" />
      <div class="content">
        <img src="@/assets/images/memberShip/logo.svg" />
        <span>{{ $t("chaneseReiki") }}</span>
      </div>
    </div>
    <!-- 联系我们 -->
    <div class="concatUs">
      <div class="concatWrap">
        <div class="left">
          <div class="item">
            <p>{{ $t("courseLink") }}</p>
            <ul>
              <li @click="gotoCourseDetail('course_angel')">天使灵气课程</li>
              <li @click="gotoCourseDetail('course_animal')">动物灵气课</li>
              <li @click="gotoCourseDetail('course_candle')">魔法蜡烛课程</li>
              <li @click="gotoCourseDetail('course_crystal')">水晶灵气课</li>
              <li @click="gotoCourseDetail('course_egyptian')">古埃及灵气课</li>
              <li @click="gotoCourseDetail('course_green_tara')">绿度母灵气课</li>
              <li @click="gotoCourseDetail('course_kundalini')">昆达里尼灵气课</li>
              <li @click="gotoCourseDetail('course_karuna')">Karuna灵气导师课</li>
              <li @click="gotoCourseDetail('course_money')">金钱灵气课</li>
              <li @click="gotoCourseDetail('course_reiki_1_2_3')">臼井圣火灵气1-2-3阶</li>
              <li @click="gotoCourseDetail('course_reiki_master')">臼井圣火灵气导师课</li>
              <li @click="gotoCourseDetail('course_shamballa')">香巴拉灵气课</li>
              <li @click="gotoCourseDetail('course_violet')">紫火灵气课</li>
            </ul>
          </div>
          <div class="item">
            <p>{{ $t("reikiArticle") }}</p>
            <ul>
              <li @click="gotoArticleDetail('artical_five')">灵气五戒</li>
              <li @click="gotoArticleDetail('article_distance_reiki')">发送远距离灵气</li>
              <li @click="gotoArticleDetail('article_reiki_auras')">运用灵气来观察光环和前世</li>
              <li @click="gotoArticleDetail('article_reiki_meditation')">灵气冥想</li>
              <li @click="gotoArticleDetail('article_uses_of_reiki')">灵气日常运用</li>
            </ul>
          </div>
          <div class="item">
            <p>{{ $t("contactUs") }}</p>
            <ul>
              <!-- <li>
                <img src="@/assets/images/icon_phone.png" />
                {{ $t("phone") }}：111111
              </li> -->
              <li>
                <img src="@/assets/images/icon_email.png" />{{
                  $t("email")
                }}：reikitraincenter@gmail.com
              </li>
              <li>
                <img src="@/assets/images/icon_wechart.png" />{{
                  $t("wx")
                }}：CRRA0001
              </li>
            </ul>
          </div>
        </div>
        <div class="right">
          <div class="erm">erm</div>
          <p>扫一扫即刻关注</p>
          <p>华人灵气协会</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {
    gotoCourseDetail(id) {
      this.$router.push({
        path: `/course/courseDetail/${id}`,
      });
    },
    gotoArticleDetail(id) {
      this.$router.push({
        path: `/resources/resourceDetail/${id}`,
      });
    },
  },
};
</script>
<style lang="less" scoped>
.bottomWrap {
  img {
    width: 100%;
    display: block;
  }
  position: relative;
  .content {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-size: contain;
    img {
      width: 80px;
      margin-bottom: 10px;
    }
    span {
      font-size: 20px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 28px;
      text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
    }
  }
}
.concatUs {
  background-color: #d9ad6a;
  padding: 0 16.6%;
  padding-top: 48px;
  padding-bottom: 76px;
  .concatWrap {
    display: flex;
    justify-content: space-around;
    .left {
      flex: 1;
      display: flex;
      .item {
        &:nth-child(1) {
          margin-right: 81px;
        }
        &:nth-child(2) {
          margin-right: 100px;
        }
        p {
          font-size: 16px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          color: #08101e;
          line-height: 22px;
          margin-bottom: 14px;
        }
        ul li {
          font-size: 14px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          color: #333333;
          line-height: 20px;
          margin-top: 10px;
          display: flex;
          align-items: center;
          cursor: pointer;
          img {
            width: 16px;
            margin-right: 5px;
          }
        }
      }
    }
    .right {
      width: 126px;
      .erm {
        width: 110px;
        height: 110px;
        margin: 0 auto;
        padding-bottom: 10px;
        background-color: #08101e;
      }
      p {
        text-align: center;
        width: 126px;
        font-size: 14px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 20px;
      }
    }
  }
}
</style>
