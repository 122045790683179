<template>
  <div id="app">
    <nav>
      <div class="navWrap">
        <img class="logo" src="./assets/images/logo.png" />
        <router-link to="/" tag="span" @mouseenter.native="navmouseleave">{{
          $t("homePage")
        }}</router-link>
        <!-- <router-link to="/resources" tag="span">资源</router-link> -->
        <router-link
          to="/course"
          @mouseenter.native="navmouseenter('course')"
          class="course"
          tag="span"
          >{{ $t("lesson") }}</router-link
        >
        <router-link
          to="/#"
          tag="span"
          @mouseenter.native="navmouseenter('article')"
          >{{ $t("article") }}</router-link
        >
        <router-link
          to="/memberShip"
          tag="span"
          @mouseenter.native="navmouseleave"
          >{{ $t("member") }}</router-link
        >
      </div>
      <div class="personalHandle">
        <ul>
          <li>
            <span
              @click="toggleLanguage('zh')"
              class="langue"
              :class="{ active: language === 'zh' }"
              >中文</span
            >/
            <span
              @click="toggleLanguage('en')"
              class="langue"
              :class="{ active: language === 'en' }"
              >EN</span
            >
          </li>
          <!-- <li @click="login" v-if="!accountName">{{ $t("login") }}</li> -->
          <li>
            <router-link to="/shopCar" tag="span">
              <el-badge v-if="orderNum !== 0" :value="orderNum" class="badge">
                <img
                  width="14"
                  height="16.5"
                  src="./assets/images/memberShip/shopCar.png"
                  alt=""
                />
                {{ $t("shoppingCart") }}
              </el-badge>
              <div class="badge" v-else>
                <img
                  width="14"
                  height="16.5"
                  src="./assets/images/memberShip/shopCar.png"
                  alt=""
                />
                {{ $t("shoppingCart") }}
              </div>
            </router-link>
          </li>
          <li @click="login" class="personal" v-if="!accountName">
            <img
              width="15"
              height="16.5"
              src="./assets/images/memberShip/personLogin.png"
              alt=""
            />
            {{ $t("login") }}
          </li>
          <li v-else>
            <el-dropdown class="personal">
              <span class="el-dropdown-link">
                <img
                  src="./assets/images/memberShip/personLogin.png"
                  width="15"
                  height="16.5"
                />
                {{ accountName
                }}<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>
                  <router-link to="/personalCenter" tag="div">{{
                    $t("personalCenter")
                  }}</router-link></el-dropdown-item
                >
                <el-dropdown-item v-show="accountName">
                  <div @click="checkOut">{{ $t("loginOut") }}</div>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </li>
        </ul>
      </div>
    </nav>
    <login></login>
    <div class="content" id="content">
      <router-view />
    </div>
    <navItem
      v-if="showSubMenu"
      @navItemWrapLeave="navItemWrapLeave"
      :dataList="dataList"
    ></navItem>
  </div>
</template>
<script>
import Login from "@/components/login/index.vue";
import { mapGetters } from "vuex";
import navItem from "@/components/navItem.vue";
import { allCourseDataTypes } from "@/mock/course/index.js";
import { allArticleDataTypes } from "@/mock/article/index.js";
export default {
  data() {
    return {
      dataList: [],
      allCourseDataTypes,
      allArticleDataTypes,
      showSubMenu: false,
      // accountName: this.$store.getters.accountName,
      // orderNum: this.$store.getters.orderNum,
    };
  },
  computed: mapGetters([
    // 映射 this.count 为 store.state.count
    "accountName",
    "orderNum",
    "language",
  ]),
  components: {
    Login,
    navItem,
  },
  methods: {
    navmouseenter(type) {
      if (type === "course") {
        this.dataList = this.allCourseDataTypes;
        this.showSubMenu = true;
      } else if (type === "article") {
        this.dataList = this.allArticleDataTypes;
        this.showSubMenu = true;
      } else {
        this.showSubMenu = false;
      }
    },
    navItemWrapLeave() {
      this.showSubMenu = false;
    },
    navmouseleave() {
      this.showSubMenu = false;
    },
    toggleLanguage(type) {
      if (type === this.language) {
        return;
      }
      this.$i18n.locale = type;
      this.$store.commit("setLanguage", type);
    },
    checkOut() {
      this.$store.dispatch("loginOut");
      this.$router.push({
        path: "/",
      });
    },
    login() {
      this.$store.commit("setIsShowLogin", true);
    },
  },
};
</script>
<style lang="less">
@import "./assets/css/initial.css";
@import "./assets/css/github-markdown.css";
#app {
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  min-width: 1440px;
  .router-link-exact-active {
    position: relative;
    font-weight: 600 !important;

    &::after {
      display: block;
      content: "";
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 30px;
      width: 16px;
      background-color: #d9ad6a;
      height: 2px;
    }
  }
  nav {
    margin: 0 16.6%;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 62px;
    justify-content: space-between;
    border-bottom: 1px solid #edeeef;
    .logo {
      width: 62px;
      margin-right: 32px;
    }
    .navWrap {
      display: flex;
      align-items: center;
      span {
        width: 88px;
        cursor: pointer;
        text-align: center;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #08101e;
        line-height: 22px;
      }
    }
    .personalHandle {
      ul {
        display: flex;
        li {
          margin-left: 30px;
          cursor: pointer;
          .el-dropdown {
            font-size: 16px;
            color: #000;
          }
          .langue {
            font-family: PingFang-SC, PingFang-SC;
            font-size: 16px;
            font-weight: 400;
            color: #08101e;
            line-height: 24px;
            text-align: center;
            font-style: normal;
            &.active {
              font-weight: bold;
              color: #d9ad6a;
            }
          }
          .badge,
          &.personal,
          .el-dropdown-link {
            display: flex;
            align-items: center;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 14px;
            color: #08101e;
            line-height: 22px;
            text-align: left;
            font-style: normal;
            img {
              display: block;
              margin-right: 4px;
            }
          }
        }
      }
    }
  }
  .content {
    flex: 1;
    overflow: auto;
  }
}
.el-dropdown-menu__item {
  padding: 0 !important;
  width: 96px;
  text-align: center;
}
.el-dropdown-menu__item:focus,
.el-dropdown-menu__item:not(.is-disabled):hover {
  background: rgba(217, 173, 106, 0.12) !important;
  color: #000 !important;
}
</style>
