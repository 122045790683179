export default {
  img: require("@/assets/images/banner.png"),
  banner: require("@/assets/images/banner.png"),
  title: "天使灵气课程",
  introduction:
    "天使知道我们的一切，他们知道并理解我们的悲伤、焦虑、担心，他们知道我们发出的振动以及请求是什么",
  id: "course_angel",
  auth: "华人灵气研究协会",
  time: "2014-01-01",
  relate: ["course_candle"],
  reikiEffect: [
    "与天使进行链接, 感知天使能量",
    "找到自己的守护天使",
    "获得天使的祝福",
    "改善睡眠质量",
    "增加爱、慈悲的能量",
    "减轻内心痛楚、烦躁不安、恐惧感",
    "增强自觉、直觉与内在洞察力",
    "促进个人成长、提升灵性修为",
  ],
  type: "ccourse",
  content: `
1.天使灵气是一个强大的，且温柔的疗愈系统，允许非常高的振动能量，“光之存有”的高频能量，帮助我们释放身体，祖先，情感和业力的不平衡。与臼井灵气不同的是，在天使灵气疗愈系统内，点化是由天使之光王国完成的，并由他们自己的疗愈天使给予到每个人。在天使灵气中，没有宗教之分，也没有时间空间之分，它是直接来自天使。

2.天使知道我们的一切，他们知道并理解我们的悲伤、焦虑、担心，他们知道我们发出的振动以及请求是什么。这些高度先进的“光之存有”，视我们为一个光的管道，并在我们的整个能量场上工作，包含我们的身体、情绪体、心智体、灵性体。在这四个维度上，疗愈天使们移除了所有种类的障碍，包括今生，前世，跨越所有时间和化身。来自光的天使国度，感恩相遇
   
   
<h2>天使符号</h2>

1.天使符号是天使带给我们的祝福，我们可以通过天使符号，更好的链接天使。每个符号有自己的含义，针对不同主题可以选择对应的符号以及对应天使进行疗愈。49个天使符号的学习，也会增加我们自身与天使的链接。
   
   
<h2>天使能量产品</h2>

1.每个大天使有自己的掌管领域，每个植物也有自己独特的疗愈属性。通过结合植物与天使的能量，我们可以通过制作天使相关能量产品。天使能量周边，可以日常净化自己与空间，改善情感，金钱，学业，事业等问题
   

<h2>第一节课</h2>

1.天使【含义/历史等介绍】 
2.天使灵气VS天使
3.四大瞭望塔天使【详细介绍】
4.七大天使介绍
5.学习如何净化空间，与光的天使国度链接
6.你与天使【如何链接，如何找到自己的守护天使等】
7.链接天使练习:引导冥想 


<h2>第二节课</h2>

1.49个天使灵气符号下载/含义解读
2.天使灵气的作用，准则，与其他灵气区别
3.天使灵气与脉轮结合
4.如何进行完整的天使灵气疗愈【面对面VS远程】
5.天使灵气冥想
6.制作天使光球
7.更多...


<h2>第三节课 【能量产品&祭坛】</h2>

1.学习如何布置天使圣坛
2.学习制作天使能量周边
3.比如多款大天使功能油，疗愈油，祝福油
4.比如多款大天使功能喷雾，疗愈喷雾，祝福喷雾
5.比如定制个人天使产品
6.学习天使YI式



<h2>注：知识点部分会提供录播，可反复听</h2>

1.点化部分
2.点化方式：腾讯会议/Zoom ，实时参与,如果不可以实时参与，需提供个人相关信息
3.相关信息：姓名，出生日期年月日或者照片       
4.课程价格：1111元
5.早鸟价：599元
6.复训价格：99元
7.课程时长：120分钟 [包括点化时间，重点内容介绍,不包括录播内容时间]
8.下期课程时间：咨询客服
  `,
};
