<template>
  <div class="home">
    <bannerList :bannerList="bannerList"></bannerList>
    <!-- 灵气的作用 -->
    <rikiEffect></rikiEffect>
    <!-- 课程 -->
    <courseAuth></courseAuth>
    <!-- 灵气文章 -->
    <RikiArtical></RikiArtical>
    <!-- 会员 -->
    <memberShip :memberShipData="memberShipData"></memberShip>
    <!-- 底部 -->
    <concatUs></concatUs>
  </div>
</template>

<script>
// @ is an alias to /src
import BannerList from "@/components/banner.vue";
import RikiArtical from "@/components/rikiArtical.vue";
import ConcatUs from "@/components/concatUs.vue";
import RikiEffect from "@/components/rikiEffect.vue";
import courseAuth from "@/components/courseAuth.vue";
import MemberShip from "@/components/memberShip.vue";
import Api from "@/api";
export default {
  name: "HomeView",
  components: {
    MemberShip,
    BannerList,
    RikiArtical,
    ConcatUs,
    RikiEffect,
    courseAuth,
  },
  data() {
    return {
      memberShipData: [],
      bannerList: [
        {
          src: require("@/assets/images/swipper_2.png"),
        },
        {
          src: require("@/assets/images/swipper_3.png"),
        },
        {
          src: require("@/assets/images/swipper_1.png"),
        },
      ],
    };
  },
  mounted() {
    this.getMember();
  },
  methods: {
    async getMember() {
      try {
        const { code, data } = await Api.memberwall({ userName: "" });
        if (code === 0 && data && data.length > 0) {
          this.memberShipData = data.map((item) => item.userName);
        }
      } catch (e) {
        this.$message.error(e);
      }
    },
  },
};
</script>
<style lang="less" scoped>
.home {
  img {
    width: 100%;
  }
  .chineseReikiResear {
    padding-bottom: 63px;
    .titleWrap {
      margin: 0 auto 50px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .title {
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 80px;
        }
        span {
          padding: 0 20px;
        }
      }
      .englishName {
        font-size: 16px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #d9ad6a;
        line-height: 22px;
      }
      .note {
        margin-top: 32px;
        font-size: 16px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 22px;
      }
    }
    .info {
      display: flex;
      justify-content: center;

      .img {
        width: 296px;
      }
      .textWrap {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin-left: 30px;
        .title {
          font-size: 20px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          color: #08101e;
          line-height: 22px;
          margin-bottom: 16px;
          text-align: center;
        }
        .text {
          width: 688px;
          background: rgba(217, 173, 106, 0.1);
          height: 28px;
          font-size: 18px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          color: #d9ad6a;
          line-height: 28px;
          margin-top: 20px;
          text-align: center;
        }
      }
    }
  }
  .memberShip {
    .bg {
      background-color: #fff;
      height: 100px;
    }
    .titleWrap {
      margin: 100px auto 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .title {
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 80px;
        }
        span {
          padding: 0 20px;
        }
      }
      .englishName {
        font-size: 16px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #d9ad6a;
        line-height: 22px;
      }
      .note {
        margin-top: 24px;
        margin-bottom: 68px;
        font-size: 16px;
        width: 592px;
        text-align: center;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 22px;
      }
    }
    .memberShipList {
      ul {
        display: flex;
        justify-content: center;
        li {
          width: 374px;
          height: 322px;
          border-radius: 12px 0px 12px 0px;
          border: 1px solid #d9ad6a;
          &:nth-child(1) {
            margin-right: 87px;
          }
          .title {
            width: 374px;
            height: 48px;
            background: #d9ad6a;
            border-radius: 12px 0px 0px 0px;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
              width: 20px;
            }
            span {
              font-size: 18px;
              font-family: PingFangSC, PingFang SC;
              font-weight: 400;
              color: #ffffff;
              line-height: 28px;
            }
          }
          p {
            text-align: center;
            line-height: 45px;
            color: #666;
            &.active {
              color: #d9ad6a;
            }
          }
        }
      }
    }
    .memberWall {
      .titleWrap {
        margin-top: 68px;
        margin-bottom: 49px;
        .title {
          font-size: 28px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          color: #08101e;
          line-height: 40px;
          margin-bottom: 10px;
        }
        .subTitle {
          display: flex;
          align-items: center;
          .info {
            font-size: 16px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            color: #666666;
            line-height: 24px;
            padding-right: 16px;
          }
          .more {
            font-size: 16px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            color: #d9ad6a;
            line-height: 24px;
          }
          img {
            width: 16px;
            height: 16px;
          }
        }
      }
      .member {
        height: 300px;
        background-color: #fcf7f1;
      }
    }
  }
}
</style>
