<template>
  <div class="reikiArticel">
    <div class="titleWrap">
      <div class="title">
        <img src="@/assets/images/title_left.png" alt="" />
        <span>灵气文章</span>
        <img src="@/assets/images/title_right.png" alt="" />
      </div>
      <p class="englishName">ReikiArticel</p>
    </div>
    <div class="courseList">
      <ul>
        <li v-for="(item, index) in allArticleData.slice(0, 3)" :key="index">
          <div class="bg"></div>
          <div class="item">
            <img :src="item.img" alt="" />
            <div class="itemInfo">
              <span class="name">{{ item.title }}</span>
              <span class="detail">{{ item.introduction }}</span>
              <span class="learMoreBtn" @click="learnMore(item)">{{
                $t("learnMore")
              }}</span>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div class="imgBottom">
      <img src="@/assets/images/rikiArticlBg.png" />
    </div>
  </div>
</template>
<script>
import { allArticleData } from "@/mock/article/index.js";
export default {
  data() {
    return {
      allArticleData,
    };
  },
  methods: {
    learnMore(item) {
      const { id } = item;
      this.$router.push({
        path: `/resources/resourceDetail/${id}`,
      });
    },
  },
};
</script>
<style lang="less" scoped>
.reikiArticel {
  background-color: #fcf7f1;
  .titleWrap {
    padding: 100px 0 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .title {
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 80px;
      }
      span {
        padding: 0 20px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 28px;
        color: #08101e;
        line-height: 40px;
        text-align: center;
        font-style: normal;
      }
    }
    .englishName {
      font-size: 16px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 300;
      color: #d9ad6a;
      line-height: 22px;
    }
  }
  .courseList {
    margin: 0 16.6%;
    ul {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      li {
        display: flex;
        flex-direction: column;
        width: 30%;
        position: relative;
        &:nth-child(2) {
          .bg {
            top: 18%;
          }
          .item {
            top: 110px;
          }
        }
        .bg {
          width: 100%;
          height: 56%;
          border: 8px solid #d9ad6a;
          position: absolute;
          left: 0;
          top: 0;
          right: 0;
        }
        .item {
          position: relative;
          left: 40px;
          top: 30px;
          background-color: #fcf7f1;
          img {
            width: 100%;
          }
          .itemInfo {
            margin-top: 12px;
            display: flex;
            align-items: start;
            justify-content: space-between;
            flex-direction: column;
            .name {
              font-size: 20px;
              line-height: 30px;
              font-weight: 400;
            }
            .detail {
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
              font-size: 14px;
              color: #666;
              line-height: 22px;
            }

            .learMoreBtn {
              margin-top: 20px;
              width: 80px;
              height: 30px;
              line-height: 30px;
              text-align: center;
              border: 1px solid #08101e;
              font-size: 14px;
              color: #333;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
  .imgBottom {
    background-color: #fff;
    margin-top: 150px;
    img {
      width: 100%;
    }
  }
}
</style>
