export default {
  img: require("@/assets/images/banner.png"),
  banner: require("@/assets/images/banner.png"),
  title: "昆达里尼灵气课",
  introduction: "在昆达里尼灵气中，你将能够以一种安全而温和的方式获得你身体的基本能量——昆达里尼能量——将它从脊柱底部提升到头顶。",
  id: "course_kundalini",
  auth: "华人灵气研究协会",
  type: "ccourse",
  relate: ["course_"],
  time: "2014-01-01",
  reikiEffect: [
    "激活昆达里尼灵气能量",
    "协助我们处理身体层面",
    "增加勇气，找回自己的天赋使命",
    "非常的温柔，坚韧，沉静、内敛、有力",
    "减轻内心痛楚、烦躁不安、恐惧感",
    "改善睡眠质量、减轻神经紧张",
    "与我们的脐轮/海底轮/太阳神经丛息息相关",
    "增强自觉、直觉与内在洞察力",
    "促进个人成长、提升灵性修为",
  ],
  content: `
在瑜伽体系中，昆达里尼是一种非常先进的冥想练习，通过这个练习，练习者将学会沿着称为Sushumna的主要能量通道(子午线)激活和提升昆达里尼能量。昆达里尼灵气是由冥想大师Ole Gabrielsen(丹麦)发现的一种高级灵气形式。这是他与扬升大师Kuthumi链接的结果。他从瑜伽系统中吸取了不同的技巧，并将它们应用到灵气系统中，形成了昆达里尼灵气系统。

在昆达里尼灵气中，你将能够以一种安全而温和的方式获得你身体的基本能量——昆达里尼能量——将它从脊柱底部提升到头顶。

<h2>课程介绍</h2>

<h2>一阶</h2>

昆达里尼灵气历史

激活星光体&脉轮

昆达里尼灵气1级能量细节

昆达里尼灵气1级能量疗愈效果

昆达里尼冥想

如何给自己一个完整的灵气治疗

如何解决特定的问题

...


<h2>二阶</h2>

2级与1级区别

加强能量经脉

唤醒昆达里尼能量

昆达里尼灵气2级能量的细节(详细)

昆达里尼灵气2级能量疗愈效果

学习通过冥想来增加昆达里尼之火的力量

如何净化

...



<h2>三阶</h2>

如何运用昆达里尼灵气给自己&他人进行疗愈

进行三阶昆达里尼灵气点化

学习对应灵气符号

学习如何平衡各类主题

一个真正的昆达里尼灵气大师的价值和精神取向

且包括下面灵气的使用方法：

钻石灵气

水晶灵气【以太层灵性水晶】

DNA灵气

场所灵气

前世灵气

出生创伤灵气


注：知识点部分会提供录播，可反复听



<h2>四阶</h2>

导师阶知识详细介绍

如何进行教学

如何给他人进行点化

...

<h2>上课形式</h2>

点化方式：腾讯会议/Zoom ，实时参与
如果不可以实时参与，需提供个人相关信息
相关信息：姓名，出生日期年月日或者照片       

课程时长：3个晚上，一个4小时直播 包括点化时间，重点内容介绍 - 不包括录播内容时间
下期课程时间：咨询客服


1-2阶      666元     399元【前50位】

3阶        999元     499元【前50位】

4阶        2222元    999元【前50位】


1-3阶连报   699元【前50位】

1-4阶连报   1499元【前50位】
  `,
};