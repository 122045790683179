export default {
  img: require("@/assets/images/banner.png"),
  banner: require("@/assets/images/banner.png"),
  title: "紫火灵气课",
  introduction: "紫火灵气是圣哲曼大师从源头引入的礼物。",
  id: "course_violet",
  auth: "华人灵气研究协会",
  type: "ccourse",
  relate: ["course_"],
  time: "2014-01-01",
  reikiEffect: [
    "消除负面情绪【比如恐惧，失落，悲伤等】",
    "协助净化自己沾染到的不属于自己的负能量",
    "协助净化空间，物品中的负能量",
    "各种深层创伤【比如Ye力，家庭，童年伤害等",
    "提升与地球的链接",
    "提升与高维团队的链接",
  ],
  content: `
紫火灵气是圣哲曼大师从源头引入的礼物，简单来说，这是一个可以净化负能量的工具，并且可以从表层到深层溯源，找到负能量产生的源头，进行处理。

这个课程，除了可以很好的运用自己的‘疗愈能量’，还可以很好的运用大家的‘杀戮能量’。

如果你是一个很容易被负能量感染的人

如果你是一个‘烂好人’

如果你是一位疗愈师，日常需要做一些释放进行平衡

如果你喜欢灵气，想要探索紫火灵气

或者，只是为了喜悦&轻松，不想在负重前行了

那么，欢迎选择紫火灵气！

<h2>能量产品</h2>

课程引入了手作，这部分是我个人添加的内容。

这部分产品，主要是协助净化空间，协助大家睡眠，协助情绪稳定等，也可以根据个人定制化。

<h2>课程介绍</h2>

第一部分【紫火相关知识 - 重点学习】

1、金银紫火的起源，应用场景

2、学习1-2阶对应灵气符号【20个】

3、如何进行面对面以及远程疗愈【各类主题举例】

4、如何净化&加持 物品

5、如何净化&加持 空间/Feng水

6、如何给地球，其他星体进行紫火

6、精微体7个层面，紫火的运用

7、、、


第二部分【灵性防护专题 - 独家整理】

1、什么是防护？为什么需要做防护？

2、如何运用紫火+蜡烛结合进行防护?

3、怎么给空间做防护？

4、如何运用肯定句进行能量释放

5、、、



第三部分 【能量产品 - 独家整理】

1、学习常见净化产品使用方法【鼠尾草/圣木等】

2、学习制作圣水

3、学习制作净化油，净化喷雾

4、学习制作安眠油，安眠喷雾

5、学习定制净化产品

6、其他能量产品





第四部分  【紫火3-4阶】

1、学习紫火3-4阶符号【20个】

2、学习莲花符号【1个】

3、学习如何深层进行紫火灵气

4、学习如何进行紫火灵气点化

5、学习如何给他人授权符号

6、学习如何点化他人/自我点化

7、学习如何教学

8、...



<h2>上课形式</h2>


注：知识点部分会提供录播，可反复听

点化部分

点化方式：腾讯会议/Zoom ，实时参与
         如果不可以实时参与，需提供个人相关信息

相关信息：姓名，出生日期年月日或者照片       

课程价格：

1-2阶  原价699  399「仅限前80个名额」

3-4阶  原价899  499「仅限前50个名额」

连报：798
  `,
};