<template>
  <div>
    <el-drawer
      :visible.sync="drawer"
      :direction="direction"
      :before-close="handleClose"
      custom-class="loginRegist"
      size="32%"
    >
      <div class="cont">
        <div class="title">
          <img src="@/assets/images/memberShip/login_right.png" alt="" />
          <span>{{ $t("quickLoginRegist") }}</span>
        </div>
        <p class="currentHandler">
          {{
            activeName === "login"
              ? $t("pleaseLoginAccount")
              : $t("pleaseRegistAccount")
          }}
        </p>
        <el-tabs v-model="activeName" @tab-click="handleClick" :stretch="true">
          <el-tab-pane :label="$t('login')" name="login">
            <el-form
              ref="loginForm"
              :model="loginForm"
              :rules="loginFormRules"
              label-position="top"
            >
              <el-form-item :label="$t('accountEmail')" prop="accountName">
                <el-input
                  :placeholder="$t('enter')"
                  v-model="loginForm.accountName"
                ></el-input>
              </el-form-item>
              <el-form-item :label="$t('password')" prop="pwd">
                <el-input
                  :placeholder="$t('enter')"
                  type="password"
                  v-model="loginForm.pwd"
                ></el-input>
              </el-form-item>
              <el-form-item>
                <el-button
                  class="loginBtn"
                  @click="login"
                  :loading="loginLoading"
                  >{{ $t("login") }}</el-button
                >
                <p class="forgetPwd" @click="handlerForgetPwd">
                  {{ $t("forgetPwd") }}
                </p>
              </el-form-item>
            </el-form>
          </el-tab-pane>
          <el-tab-pane :label="$t('register')" name="register">
            <el-form
              ref="registForm"
              :model="registForm"
              label-position="top"
              :rules="registerRules"
            >
              <el-form-item :label="$t('account')" prop="accountName">
                <el-input
                  :placeholder="$t('enter')"
                  v-model="registForm.accountName"
                ></el-input>
              </el-form-item>
              <el-form-item :label="$t('email')" prop="email">
                <el-input
                  :placeholder="$t('enter')"
                  v-model="registForm.email"
                ></el-input>
              </el-form-item>
              <el-row :gutter="20">
                <el-col :span="12">
                  <el-form-item :label="$t('firstName')" prop="firstName">
                    <el-input
                      :placeholder="$t('enter')"
                      v-model="registForm.firstName"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item :label="$t('lastName')" prop="lastName">
                    <el-input
                      :placeholder="$t('enter')"
                      v-model="registForm.lastName"
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-form-item :label="$t('password')" prop="pwd">
                <el-input
                  type="password"
                  :placeholder="$t('enter')"
                  v-model="registForm.pwd"
                ></el-input>
              </el-form-item>
              <el-form-item :label="$t('confirmpwd')" prop="confirmPwd">
                <el-input
                  type="password"
                  :placeholder="$t('enter')"
                  v-model="registForm.confirmPwd"
                ></el-input>
              </el-form-item>
              <el-form-item>
                <div class="beforeCheck">
                  <div @click="checked = !checked">
                    <img
                      v-show="checked"
                      src="@/assets/images/memberShip/icon_checked.png"
                    />
                    <img
                      v-show="!checked"
                      src="@/assets/images/memberShip/icon_no_checked.png"
                    />
                  </div>
                  {{ $t("iAgree") }}
                  <span @click="privacy()">[{{ $t("privacyPolicy") }}]</span>
                </div>
                <el-button
                  class="registBtn"
                  @click="register"
                  v-throttle
                  :loading="registLoading"
                  >{{ $t("register") }}</el-button
                >
              </el-form-item>
            </el-form>
          </el-tab-pane>
        </el-tabs>
      </div>
      <el-drawer
        :append-to-body="true"
        :visible.sync="innerDrawer"
        custom-class="loginRegist"
        :before-close="handleCloseInner"
      >
        <div class="cont">
          <div class="title">
            <img src="@/assets/images/memberShip/login_right.png" alt="" />
            <span>{{ $t("forgetPwd") }}</span>
          </div>
          <p class="currentHandler forgetPwdHandler">
            {{ $t("getNewThoughEmail") }}
          </p>
          <el-form
            ref="forgetForm"
            :model="forgetForm"
            :rules="forgetFormRules"
            label-position="top"
            @submit.native.prevent
          >
            <el-form-item :label="$t('email')" prop="email">
              <el-input
                :placeholder="$t('enter')"
                v-model="forgetForm.email"
                @keyup.enter.native="findPwd"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button
                class="loginBtn"
                @click="findPwd"
                v-throttle
                :loading="findPwdLoading"
                >{{ $t("sedNewPwd") }}</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </el-drawer>
    </el-drawer>
    <el-dialog
      :title="$t('privacyPolicy')"
      :visible.sync="contendVisible"
      custom-class="pricyDialog"
    >
      <div class="contend markdown-body" v-html="markdownToHtml"></div>
      <div class="pricySure" @click="contendVisible = false">
        {{ $t("sure") }}
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Api from "@/api";
import priacyJson from "@/pageConfig/privacy.js";
export default {
  data() {
    return {
      loginLoading: false,
      registLoading: false,
      findPwdLoading: false,
      drawer: false,
      direction: "rtl",
      activeName: "login",
      innerDrawer: false,
      contendVisible: false,
      checked: false,
      loginForm: {
        accountName: "",
        pwd: "",
      },
      registForm: {
        accountName: "",
        pwd: "",
        confirmPwd: "",
        email: "",
        firstName: "",
        lastName: "",
      },
      forgetForm: {
        email: "",
      },
    };
  },
  computed: {
    ...mapGetters([
      // 映射 this.count 为 store.state.count
      "isShowLogin",
    ]),
    loginFormRules() {
      return {
        accountName: [
          {
            required: true,
            message: this.$t("pleaseEnteracoremail"),
            trigger: "change",
          },
        ],
        pwd: [
          {
            required: true,
            message: this.$t("pleaseEnterPwd"),
            trigger: "change",
          },
        ],
      };
    },
    registerRules() {
      return {
        accountName: [
          {
            required: true,
            message: this.$t("enterAccount"),
            trigger: "change",
          },
        ],
        pwd: [
          {
            required: true,
            message: this.$t("pleaseEnterPwd"),
            trigger: "change",
          },
        ],
        confirmPwd: [
          {
            required: true,
            message: this.$t("pleaseEnterConfirmPwd"),
            trigger: "change",
          },
          { validator: this.validateconfirmPwd, trigger: "change" },
        ],
        email: [
          { required: true, message: this.$t("enterEmail"), trigger: "change" },
          {
            type: "email",
            message: this.$t("enterCorrectEmail"),
            trigger: ["blur", "change"],
          },
        ],
        firstName: [
          { required: true, message: this.$t("enterFirst"), trigger: "change" },
        ],
        lastName: [
          { required: true, message: this.$t("enterLast"), trigger: "change" },
        ],
      };
    },
    forgetFormRules() {
      return {
        email: [
          { required: true, message: this.$t("enterEmail"), trigger: "change" },
          {
            type: "email",
            message: this.$t("enterCorrectEmail"),
            trigger: ["blur", "change"],
          },
        ],
      };
    },
    markdownToHtml() {
      var cleanedHtmlString = this.$md(priacyJson.content);
      return cleanedHtmlString;
    },
  },
  watch: {
    isShowLogin: {
      handler(val) {
        this.drawer = val;
      },
      immediate: true,
    },
    "$i18n.locale": function () {
      this.$nextTick(() => {
        this.$refs.forgetForm && this.$refs.forgetForm.clearValidate();
        this.$refs.loginForm && this.$refs.loginForm.clearValidate();
        this.$refs.registForm && this.$refs.registForm.clearValidate();
      });
    },
  },
  methods: {
    privacy() {
      this.contendVisible = true;
    },
    validateconfirmPwd(rule, value, callback) {
      if (value === "") {
        callback(new Error(this.$t("pleaseEnterConfirmPwd")));
      } else if (value !== this.registForm.pwd) {
        callback(new Error(this.$t("pwdError")));
      } else {
        callback();
      }
    },
    handleCloseInner() {
      this.$refs["forgetForm"].resetFields();
      this.innerDrawer = false;
    },
    async login() {
      this.loginLoading = true;
      this.$refs["loginForm"].validate(async (valid) => {
        if (valid) {
          try {
            await this.$store.dispatch("login", this.loginForm);
            this.$store.commit("setIsShowLogin", false);
            // this.$router.push({
            //   path: "/personalCenter",
            // });
            await this.cartPageList();
            window.location.reload();
          } catch (e) {
            console.log(e);
          } finally {
            this.loginLoading = false;
          }
        }
      });
    },
    async register() {
      if (!this.checked) {
        this.$message({
          message: this.$t("agreePolice"),
          type: "warning",
        });
        return;
      }

      this.$refs["registForm"].validate(async (valid) => {
        if (valid) {
          try {
            this.registLoading = true;
            const { code } = await Api.registerUser({
              ...this.registForm,
            });
            if (code === 0) {
              this.$refs["loginForm"].resetFields();
              this.$refs["registForm"].resetFields();
              this.activeName = "login";
              this.$message.success(this.$t("registSuccess"));
            }
          } catch (error) {
            this.$message.error(error);
          } finally {
            this.registLoading = false;
          }
        }
      });
    },
    findPwd() {
      this.$refs["forgetForm"].validate(async (valid) => {
        if (valid) {
          try {
            this.findPwdLoading = true;
            const { code } = await Api.sedPwdByEmail({
              email: this.forgetForm.email,
            });
            if (code === 0) {
              this.innerDrawer = false;
              this.$refs["forgetForm"].resetFields();
              this.$message.success(this.$t("emailSuccess"));
            }
          } catch (err) {
            this.$message.error(err);
          } finally {
            this.findPwdLoading = false;
          }
        }
      });
    },
    handlerForgetPwd() {
      this.innerDrawer = true;
    },
    handleClick() {
      this.$refs["loginForm"].resetFields();
      this.$refs["registForm"].resetFields();
    },
    handleClose() {
      this.$refs["loginForm"].resetFields();
      this.$refs["registForm"].resetFields();
      this.$store.commit("setIsShowLogin", false);
    },
    //获取购物车列表
    async cartPageList() {
      try {
        const { code, data } = await Api.cartPageList({
          pageIndex: 1,
          pageSize: 10,
        });
        if (code === 0) {
          this.$store.commit("setOrderNum", data.total);
        }
      } catch (err) {
        this.$message.error(err);
      }
    },
  },
};
</script>
<style lang="less" scoped>
.loginRegist {
  .cont {
    padding: 0 54px 0 54px;
    .title {
      display: flex;
      align-items: center;
      img {
        width: 11px;
        margin-right: 12px;
      }
      span {
        font-size: 28px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #08101e;
        line-height: 40px;
      }
    }
    .currentHandler {
      margin-top: 7px;
      font-size: 14px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.6);
      line-height: 20px;
      &.forgetPwdHandler {
        padding-bottom: 16px;
        margin-bottom: 16px;
        border-bottom: 1px solid #edeeef;
      }
    }
    ::v-deep {
      input {
        border: none;
        background: #f4f4f4;
      }

      .el-form-item__label {
        line-height: 22px;
      }
      .el-tabs__item {
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.6);
      }
      .el-tabs__item.is-active {
        color: #08101e;
      }
      .el-tabs__active-bar {
        background-color: #d9ad6a;
      }
    }
    .loginBtn,
    .registBtn {
      height: 44px;
      background: #08101e;
      font-size: 16px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      text-align: center;
      color: #ffffff;
      cursor: pointer;
      width: 100%;
    }
    .beforeCheck {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 8px;
      img {
        width: 16px;
        margin-right: 4px;
        cursor: pointer;
      }
      font-size: 14px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.8);
      line-height: 20px;
      span {
        cursor: pointer;
        color: #d9ad6a;
      }
    }
    .forgetPwd {
      height: 22px;
      font-size: 16px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      color: #08101e;
      line-height: 22px;
      text-align: center;
      margin: 10px auto 0;
      cursor: pointer;
      position: relative;
      width: 125px;
      overflow: hidden;
      &::before {
        content: "";
        width: 125px;
        position: absolute;
        display: inline-block;
        border-bottom: 1px solid #08101e;
        bottom: 0;
        left: -125px;
      }
      &:hover::before {
        left: 0;
        transition: all linear 1s;
      }
    }
  }
}
</style>
