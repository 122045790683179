export default {
  img: require("@/assets/images/banner.png"),
  banner: require("@/assets/images/banner.png"),
  title: "绿度母灵气课",
  introduction: "当我们被点化后，调用绿度母灵气的人实际上是通过连接她的智慧和慈悲的能量来协助人类各方面成长",
  id: "course_green_tara",
  auth: "华人灵气研究协会",
  type: "ccourse",
  relate: ["course_green_tara"],
  time: "2014-01-01",
  reikiEffect: [
    "克服八大内在业障－骄奢、谬见、愤怒、仇恨、痴狂、贪婪、痛苦、怀疑",
    "以更高效体验生命课题，避免陷入循环痛苦执念",
    "更好的链接诸佛，各类度母，提升开悟",
    "加强内在力量，改善提升能量场",
    "获取高维知识，协助我们更好的显化各类主题人生目标",
    "让我们身体更加健康&美容养颜",
  ],
  content: `
  在西藏，绿度母女神被尊为智慧菩萨，西藏的女性守护神。被认为是21度母之首，讲佛教传入西藏起到了很大的推动作用。

  她的绿度母心咒有著名的 Oṃ Tāre Tuttāre Ture Svāhā，可以用于祈请她的协助。
  
  绿度母通常被描绘成左手持一朵莲花，据说可以保护人类穿越存在之海；对人类有强烈的悲悯之心。
  
  “绿”是春天的颜色，代表万物生生不息的滋长之气，蕴含一片新生的契机。
  
  “度”是度化，自度而度人。“己欲立而立人”，“己欲达而达人”。是助人工作的本质。
  
  “母”是德行。是以母性的厚德之道与本源的根性，来表现大千世界的涵容心。
  
  当我们被点化后，调用绿度母灵气的人实际上是通过连接她的智慧和慈悲的能量来协助人类各方面成长。

<h2>课程介绍</h2>

<h2>绿度母一阶</h2>

1、Seichim与绿度母 Seichim介绍

2、Seichim与佛教

3、绿度母的起源，象征，名字，与佛的关联

4、绿度母心咒与心法，度母心咒

5、绿度母与二十一度母，白度母等介绍

6、修行度母的好处，日常如何修行

7、度母的4个形式

8、一些度母流行咒语的补充

9、、、、



<h2>绿度母二阶</h2>

1、如何运用绿度母疗Yu[面对面&远程]

2、如何运用绿度母加持物品

3、TAM 和 HRIH 灵气符号点化与学习

4、绿度母之心冥想练习

5、藏族灵珠灌顶

6、更多...



<h2>上课形式</h2>


点化方式：腾讯会议 /Zoom，实时参与; 如果不可以实时参与，需提供个人相关信息

相关信息：姓名，出生日期年月日或者照片       

课程价格：499元

早鸟价格：399元「仅限前30个名额」

复训价格：99元

课程时长：120分钟

包括点化时间，重点内容介绍

不包括录播内容时间

下期课程：咨询客服

  `,
};