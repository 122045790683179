<template>
  <div>
    <!-- 华人灵气协会 -->
    <div class="chineseReikiResear">
      <div class="titleWrap">
        <div class="title">
          <img src="@/assets/images/title_left.png" alt="" />
          <span>华人灵气研究协会</span>
          <img src="@/assets/images/title_right.png" alt="" />
        </div>
        <p class="englishName">CHINESE REIKI RESEARCH ASSOCIATION</p>
        <p class="note">
          华人灵气研究协会是一个聚集华人灵气爱好者的协会，协会成员之间可以互相交换灵气以及探讨灵气发展
        </p>
      </div>
      <div class="info">
        <div class="img"><img src="@/assets/images/yujia.png" /></div>
        <div class="textWrap">
          <p class="title">华人灵气研究协会（CRRA）中心准则:</p>
          <p class="text">诚实正直</p>
          <p class="text">尊重他人的价值观和选择</p>
          <p class="text">从最高善和表达爱出发</p>
          <p class="text">对结果保持开放的心态，而不是执着于结果</p>
          <p class="text">信任灵气（相信存在更高的力量，不管用什么</p>
          <p class="text">信任自己</p>
          <p class="text">全然的接收，全然的贡献</p>
        </div>
      </div>
    </div>
    <!-- 会员制度 -->
    <div class="memberShip">
      <div class="bg">
        <img src="@/assets/images/memberShipBg.png" />
      </div>
      <div class="titleWrap">
        <div class="title">
          <img src="@/assets/images/title_left.png" alt="" />
          <span>会员制度</span>
          <img src="@/assets/images/title_right.png" alt="" />
        </div>
        <p class="englishName">MEMBERSHIP</p>
        <p class="note">
          你可以在一年中的任何时候加入，它不是基于日历年，而是基于你的个人开始日期。
          会员开放给所有独立的灵气从业者和导师。
        </p>
      </div>
      <div class="memberShipList">
        <ul>
          <li>
            <div class="title">
              <img src="@/assets/images/people.png" />
              <span>专业会员</span>
            </div>
            <p class="active">要成为专业会员需要完成以下课程</p>
            <p>需成为灵气大师且持有</p>
            <p>CRAA的1-3阶</p>
            <p>导师阶证书</p>
            <p>Karuna</p>
            <p>需要同上学习圣火，西藏两部分内容</p>
          </li>
          <li>
            <div class="title">
              <img src="@/assets/images/lego.png" />
              <span>联盟会员</span>
            </div>
            <p class="active">联盟会员开放开所有的灵气从业者和导师</p>
            <p>持有任意灵气证书</p>
          </li>
        </ul>
      </div>
      <div class="memberWall">
        <div class="titleWrap">
          <p class="title">会员墙</p>
          <p class="subTitle" v-show="isShowJump" @click="jumpAllVip">
            <span class="info">会员可申请展示或者不展示</span>
            <span class="more">查看所有会员</span>
            <img src="@/assets/images/right_icon.png" />
          </p>
        </div>
        <div class="member">
          <danmu
            v-if="isShowJump"
            :memberShipData="memberShipData"
            :memberShipDataHeight="memberShipDataHeight"
          ></danmu>
          <memberShipList v-else></memberShipList>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Danmu from "@/components/danmu.vue";
import memberShipList from "./memberShipList.vue";
export default {
  props: {
    isShowJump: {
      typeof: Boolean,
      default: true,
    },
    memberShipData: {
      typeof: Array,
      default: () => [],
    },
    memberShipDataHeight: {
      typeof: Number,
      default: 300,
    },
  },
  components: {
    Danmu,
    memberShipList,
  },
  methods: {
    jumpAllVip() {
      this.$router.push({
        path: "/memberShip",
      });
    },
  },
};
</script>
<style lang="less" scoped>
img {
  width: 100%;
}
.chineseReikiResear {
  padding-bottom: 63px;
  .titleWrap {
    margin: 0 auto 87px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .title {
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 80px;
      }
      span {
        padding: 0 20px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 28px;
        color: #08101e;
        line-height: 40px;
        text-align: center;
        font-style: normal;
      }
    }
    .englishName {
      font-size: 16px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 300;
      color: #d9ad6a;
      line-height: 22px;
    }
    .note {
      margin-top: 32px;
      font-size: 16px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      color: #666666;
      line-height: 22px;
    }
  }
  .info {
    display: flex;
    justify-content: center;

    .img {
      width: 296px;
    }
    .textWrap {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      margin-left: 30px;
      .title {
        font-size: 20px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #08101e;
        line-height: 22px;
        margin-bottom: 16px;
        text-align: center;
      }
      .text {
        width: 688px;
        background: rgba(217, 173, 106, 0.1);
        height: 28px;
        font-size: 18px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 300;
        color: #d9ad6a;
        line-height: 28px;
        margin-top: 20px;
        text-align: center;
      }
    }
  }
}
.memberShip {
  background: #fcf7f1;
  .bg {
    background-color: #fff;
    height: 100px;
  }
  .titleWrap {
    margin: 100px auto 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .title {
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 80px;
      }
      span {
        padding: 0 20px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 28px;
        color: #08101e;
        line-height: 40px;
        text-align: center;
        font-style: normal;
      }
    }
    .englishName {
      font-size: 16px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 300;
      color: #d9ad6a;
      line-height: 22px;
    }
    .note {
      margin-top: 24px;
      margin-bottom: 68px;
      font-size: 16px;
      width: 592px;
      text-align: center;
      font-family: PingFangSC, PingFang SC;
      font-weight: 300;
      color: #666666;
      line-height: 22px;
    }
  }
  .memberShipList {
    ul {
      display: flex;
      justify-content: center;
      li {
        width: 374px;
        height: 322px;
        border-radius: 12px 0px 12px 0px;
        border: 1px solid #d9ad6a;
        &:nth-child(1) {
          margin-right: 87px;
        }
        .title {
          width: 374px;
          height: 48px;
          background: #d9ad6a;
          border-radius: 12px 0px 0px 0px;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 20px;
          }
          span {
            font-size: 18px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            line-height: 28px;
          }
        }
        p {
          text-align: center;
          line-height: 45px;
          color: #666;
          &.active {
            color: #d9ad6a;
          }
        }
      }
    }
  }
  .memberWall {
    .titleWrap {
      margin-top: 68px;
      margin-bottom: 49px;
      .title {
        font-size: 28px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #08101e;
        line-height: 40px;
        margin-bottom: 10px;
      }
      .subTitle {
        display: flex;
        align-items: center;
        cursor: pointer;
        .info {
          font-size: 16px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          color: #666666;
          line-height: 24px;
          padding-right: 16px;
        }
        .more {
          font-size: 16px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          color: #d9ad6a;
          line-height: 24px;
        }
        img {
          width: 16px;
          height: 16px;
        }
      }
    }
    .member {
      background-color: #fcf7f1;
    }
  }
}
</style>
