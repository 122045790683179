<template>
  <div class="course">
    <div class="titleWrap">
      <div class="title">
        <img src="@/assets/images/title_left.png" alt="" />
        <span>课程</span>
        <img src="@/assets/images/title_right.png" alt="" />
      </div>
      <p class="englishName">CLASS</p>
    </div>
    <div class="courseList">
      <ul>
        <li v-for="(item, index) in commonCourse" :key="index">
          <img :src="item.img" alt="" />
          <div class="itemInfo">
            <span class="name">{{ item.title }}</span>
            <span class="learMoreBtn" @click="learnMore(item)">{{
              $t("learnMore")
            }}</span>
          </div>
        </li>
      </ul>
    </div>
    <div class="courseInfo">
      <div class="infoTitle">
        <img src="@/assets/images/course_icon.png" />
        <span>动物灵气课程</span>
      </div>
      <div class="detail">
        在线动物灵气课程是为任何想要帮助那些与我们共享这个星球的不可思议的动物的人准备的，这门课程需要您有开阔的胸怀和爱
      </div>
      <div class="learMore">{{ $t("learnMore") }}</div>
    </div>
  </div>
</template>
<script>
import { commonCourse } from "@/mock/course/index.js";
import rikiList from "@/pageConfig/rikiPosition";
export default {
  data() {
    return {
      commonCourse,
      rikiList,
    };
  },
  methods: {
    learnMore(item) {
      const { id } = item;
      this.$router.push({
        path: `/course/courseDetail/${id}`,
      });
    },
  },
};
</script>
<style lang="less" scoped>
.course {
  .titleWrap {
    margin: 100px auto 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .title {
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 80px;
      }
      span {
        padding: 0 20px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 28px;
        color: #08101e;
        line-height: 40px;
        text-align: center;
        font-style: normal;
      }
    }
    .englishName {
      font-size: 16px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 300;
      color: #d9ad6a;
      line-height: 22px;
    }
  }
  .courseList {
    margin: 0 16.6%;
    ul {
      display: flex;
      align-items: center;
      justify-content: space-between;
      li {
        display: flex;
        flex-direction: column;
        width: 32.5%;
        img {
          width: 100%;
        }
        .itemInfo {
          margin-top: 12px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .name {
            font-size: 20px;
            line-height: 30px;
            font-weight: 400;
          }
          .learMoreBtn {
            width: 80px;
            height: 30px;
            line-height: 30px;
            text-align: center;
            border: 1px solid #08101e;
            font-size: 14px;
            color: #333;
            cursor: pointer;
          }
        }
      }
    }
  }
  .courseInfo {
    margin-top: 60px;
    width: 100%;
    height: 240px;
    background-image: url(@/assets/images/courseInfo.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .infoTitle {
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 24px;
        margin-right: 8px;
      }
      span {
        font-size: 20px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 28px;
        text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
      }
    }
    .detail {
      margin: 12px 0 16px;
      width: 520px;
      text-align: center;
      font-size: 16px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 300;
      color: #ffffff;
      line-height: 24px;
      text-shadow: 0px 1px 4px rgba(0, 0, 0, 0.3);
    }
    .learMore {
      width: 80px;
      height: 30px;
      border: 1px solid #ffffff;
      text-align: center;
      line-height: 30px;
      font-size: 14px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      cursor: pointer;
    }
  }
}
</style>
