export default {
  img: require("@/assets/images/banner.png"),
  banner: require("@/assets/images/banner.png"),
  title: "动物灵气课",
  introduction:
    "在线动物灵气课程是为任何想要帮助那些与我们共享这个星球的不可思议的动物的人准备的，这门课程需要您有开阔的胸怀和爱",
  id: "course_animal",
  auth: "华人灵气研究协会",
  type: "ccourse",
  relate: ["course_candle"],
  time: "2014-01-01",
  reikiEffect: [
    "舒缓长期疲乏症状，舒解痛楚",
    "手术前/后调理，加速伤患痊愈",
    "舒缓癌症治疗期间的身心不适",
    "舒缓女性经前综合症状",
    "改善皮肤素质，改善手脚冰冷",
    "减轻积存身体的负面能量",
    "改善睡眠质量、减轻神经紧张",
    "减轻内心痛楚、烦躁不安、恐惧感",
    "疗愈关节炎",
    "缓解头痛、偏头痛",
    "增强自觉、直觉与内在洞察力",
    "促进个人成长、提升灵性修为",
    "水晶阵成愿包括感情/财富等主题",
  ],
  content: `

1.了解如何与动物建立联系
2.了解如何对大小动物进行疗愈

<h2>课程内容</h2>

1.动物灵气基础内容介绍
【动物灵气能量，ICRT 动物灵气 l & Il 课程，动物灵气统一意识的好处，历史，运动中的灵气】
2.宽恕花园疗愈体验
3.接地冥想
4.生命之树符号
【符号含义，读音，写法，象征，运用场景等】
5.动物王国，动物与人进行疗愈差异
6.生命之树符号安置/点化
7.动物灵气VS动物沟通
8.如何给予一个完整的动物灵气会话
9.动物灵气练习-面对面或者远程
10.动物圈疗愈技术
11.动物同情心以及悲伤疗愈
12.ICRT相关动物的指导守则以及道德守则
13.互相组队练习
14.了解动物的基本行为,肢体语言
15.颁发国际证书
16...

<h2>以下为赠送礼物</h2>

1.介绍动物灵气
2.介绍灵气给动物和人之间的差别
3.灵气是如何帮助动物疗愈的
4....

<h2>脉轮&灵气主题</h2>

1.关于七脉轮与灵气
2.动物七脉轮与灵气
3.介绍猫/狗/鸟/野生/家畜/大型/小型等动物的脉轮
4.如何利用脉轮来进行动物灵气
5.不同动物图腾的含义
6....

<h2>动物疗法流程</h2>

1.介绍完整的动物疗法流程
2.动物扫描/七脉轮扫描法
3.设立宠物疗愈意图技巧
4.如何拿回自己的力量
5.动物灵气疗法注意事项
6....

<h2>各类场景</h2>

1.面对面动物疗愈流程
2.远距离动物疗愈流程
3.灵气用于动物临终
4.灵气用于动物超度
5.灵气运用于各类动物场景（受伤/遗弃等）
6.让动物指导你
7.与动物建立更强的链接
8....


<h2>ICRT动物灵气导师阶课程</h2>

1.接受四次动物灵气能量的点燃
2.接受动物灵气能量的符号
3.接受体验和冥想
4.加深与动物和神圣地球的体验
5.学习如何点化动物到动物灵气能量
6.学习如何教别人动物灵气
7.学习如何指导动物灵气大师的经验和四次点火
8.学习创建动物灵气课程的工具和技术
9.包括在线/线下课程技术
10.更多...
 

<h2>课程定价</h2>

1.1-2阶：1800       早鸟1600
2.导师阶：2800       早鸟2000
3.颁发ICRT证书
`,
};
