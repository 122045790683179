export default [
  {
    text: "舒缓长期疲乏症状，舒解痛楚",
    style: {
      left: "22%",
      top: "38%",
    },
  },
  {
    text: "体现爱、慈悲和万物合一的境界",
    style: {
      left: "22%",
      top: "51%",
    },
  },
  {
    text: "增强自觉、直觉与内在洞察力",
    style: {
      left: "22%",
      top: "65%",
    },
  },
  {
    text: "提高身体自愈能力",
    style: {
      left: "22%",
      top: "78%",
    },
  },
  {
    text: "人生观变得乐观、正向积极",
    style: {
      right: "22%",
      top: "38%",
    },
  },
  {
    text: "减轻积存身体的负面能量",
    style: {
      right: "22%",
      top: "51%",
    },
  },
  {
    text: "增强自信心",
    style: {
      right: "22%",
      top: "65%",
    },
  },
  {
    text: "提高意志力",
    style: {
      right: "22%",
      top: "78%",
    },
  },
];
