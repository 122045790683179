<template>
  <div>
    <div
      id="container"
      class="danmuWrap"
      :style="{ height: memberShipDataHeight + 'px' }"
    ></div>
  </div>
</template>
<script>
export default {
  props: {
    memberShipData: {
      typeof: Array,
      default: () => [],
    },
    memberShipDataHeight: {
      typeof: Number,
      default: 300,
    },
  },
  data() {
    return {
      container: null,
      num: 0,
      alreadyPlay: false,
    };
  },
  mounted() {
    // 获取容器元素
    this.container = document.getElementById("container");
    // 定义一个定时器，每隔一秒创建并插入一条弹幕
    setInterval(() => {
      // 随机选择一个弹幕内容
      var text = this.memberShipData[this.num];
      this.num = this.num + 1;
      if (this.alreadyPlay) {
        let child = this.container.children[0];
        child.remove();
      }
      if (this.num === this.memberShipData.length) {
        this.num = 0;
        this.alreadyPlay = true;
      }
      // 调用函数创建并插入弹幕
      this.createDanmu(text);
    }, 2000);
  },
  methods: {
    createDanmu(text) {
      // 创建一个span元素
      var danmu = document.createElement("span");
      // 设置其文本内容为参数值
      danmu.innerText = text;
      // 设置其类名为danmu
      danmu.className = "danmu";

      // 设置其随机的高度
      let top =
        Math.random() * (this.container.clientHeight - danmu.clientHeight);
      danmu.style.top =
        top > this.memberShipDataHeight - 48
          ? this.memberShipDataHeight - 48
          : top + "px";
      // 设置其随机的颜色
      //   danmu.style.color =
      //     "rgb(" +
      //     Math.floor(Math.random() * 256) +
      //     "," +
      //     Math.floor(Math.random() * 256) +
      //     "," +
      //     Math.floor(Math.random() * 256) +
      //     ")";
      // 将span元素插入到容器元素中
      this.container.appendChild(danmu);
      // 使用CSS动画来让span元素从右向左移动
      danmu.style.animation =
        "move " + (5 + Math.random() * 5) + "s linear infinite";
    },
  },
};
</script>
<style>
.danmuWrap {
  width: 100%;
  height: 300px;
  overflow: hidden;
  position: relative;
}
.danmu {
  width: 163px;
  height: 48px;
  background: #ffffff;
  box-shadow: 0px 8px 16px 0px rgba(119, 78, 4, 0.08);
  border-radius: 8px;
  position: absolute;
  right: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  color: #d9ad6a;
  line-height: 48px;
  text-align: center;
}
@keyframes move {
  from {
    right: 0;
  }
  to {
    right: calc(100% + 200px);
  }
}
</style>
