export default {
  img: require("@/assets/images/banner.png"),
  banner: require("@/assets/images/banner.png"),
  title: "Karuna灵气导师课",
  introduction: "",
  id: "course_karuna",
  auth: "华人灵气研究协会",
  type: "ccourse",
  relate: ["course_"],
  time: "2014-01-01",
  reikiEffect: [
    "",
    "",
    "",
    "",
    "",
    "",
  ],
  content: `
学习卡鲁娜灵气（Karuna Reiki )12个符号且接受点化&其他理论知识；导师展示如何进行卡鲁娜灵气（Karuna Reiki )点化，学生练习点化流程&其他理论知识.

<h2>课程介绍</h2>

1.净化自身&空间（气场清洁）
2.介绍灵魂，12天堂，精神等概念
3.圣火Karuna灵气体验
4.圣火Karuna灵气点燃
5.学习4个一级，4个二级，4个大师级符号
6.学习如何进行圣火灵气的体验、植入和点燃
7.带领学员练习灵气符号
8.带领学员与扫描进行结合，互相练习
9.完成Karuna灵气全部级别符号授权
10.学习诵念Karuna灵气的技巧
11.学会如何进行Karuna灵气的灌顶
12.学习Karuna灵气大师级别的灌顶技巧
13.介绍如何教学Karuna灵气，教学大纲
14.颁发ICRT证书
15.更多...
  `,
};