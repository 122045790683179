export default {
  img: require("@/assets/images/banner.png"),
  banner: require("@/assets/images/banner.png"),
  title: "臼井圣火灵气导师课",
  introduction: "灵气导师阶主要会学习如何给他人进行灵气点化/安置/点火/体验等，学习如何教学灵气，学习更多灵气技巧，向内探索自己。",
  id: "course_reiki_master",
  auth: "华人灵气研究协会",
  type: "ccourse",
  relate: ["course_"],
  time: "2014-01-01",
  reikiEffect: [
    "",
    "",
    "",
    "",
    "",
    "",
  ],
  content: `
  灵气导师阶主要会学习如何给他人进行灵气点化/安置/点火/体验等，学习如何教学灵气，学习更多灵气技巧，向内探索自己

  <h2>课程内容</h2>

1.净化自身&空间（气场清洁）
2.学习灵气大师符号并进行点化（Tibetan Daikomyo和火蛇）
3.介绍灵魂，12天堂，精神等概念
4.解释圣火III灵气的独特点燃方法
5.生命之河体验以及介绍
6.圣火点燃以及原理说明
7.学员互相练习-使用大师级符号进行疗愈
8.灵气冥想
9.详细介绍圣火灵气的发展史
10.圣爱体验
11.学习传统灵气1级，2级，高级，和大师级点化方式以及技巧
12.学习如何进行圣火灵气的体验、植入和点燃
13.学习运用灵气疗愈各种疾病
14.如何运用圣火清理灵体
15.学习大师级灵气技巧
16.学习进行教学的注意事项以及后续发展
17.合照以及颁发ICRT证书
18.更多...
  `,
};