export default {
  img: require("@/assets/images/banner.png"),
  banner: require("@/assets/images/banner.png"),
  title: "魔法蜡烛课程",
  introduction: "真正的女巫是通过古老的工艺智慧来增强自己的灵性。",
  id: "course_candle",
  auth: "华人灵气研究协会",
  type: "ccourse",
  relate: ["course_candle"],
  time: "2014-01-01",
  reikiEffect: [
    "",
    "",
    "",
    "",
    "",
    "",
  ],
  content: `
威卡课程属于自然类魔法，认为万物有灵，崇敬自然。通过天时（天象，月相，行星日等），地利（祭坛的摆放等）人合（意念的投射以及视觉化），加上植物草药的自然魔法力量，来达到魔法效果。

通过本课程的学习，您将发现真正的女巫，是通过古老的工艺智慧来增强自己的灵性。
本课程向您介绍威卡的信仰和道德准则。我们将讨论巫术的历史及其在当今日益普及的情况。
本课程将研究成为女巫的责任，并介绍如何为仪式做准备，如何择时，如何召唤神灵，召唤四元素力量以及在何处进行仪式。

在本课程中，将了解魔药的特性，了解魔法油的制作，了解魔法蜡烛的制作，以及更多魔法周边产品的制作。

任何对巫术感兴趣的人
任何对Wicca感兴趣的人
任何对精神成长感兴趣的人
任何有兴趣施行魔法的人
任何有兴趣与更高生物沟通的人

<h2>第一节课</h2>

1.魔法基础知识（魔法原理）
2.上位魔法与下位魔法
3.白魔法与黑魔法
4.威卡，三倍法则
5.四元素介绍
6.父神，母神介绍
7.魔法工具介绍
8.如何摆放祭坛
9.如何选择合适的时间进行仪式（简单方法）
10.身心净化，空间净化，仪式的准备工作

<h2>第二节课（择时）</h2>

1.月相知识，什么是月空？
2.行星日，行星时
3.在一天中选择合适的时间进行仪式
4.星期与魔法对应
5.十二星座与魔法对应
6.女巫八大节日，女巫年轮

<h2>第三节课（数字学与练习）</h2>

1.宇宙频率数字
2.魔法幻方
3.命运数字日计算
4.冥想练习
5.能量控制练习
6.视觉化练习

<h2>第四节课（蜡烛）</h2>

1.蜡烛颜色的含义
2.蜡烛火焰，烟雾等象征
3.蜡烛种类介绍
4.滴蜡占卜
5.蜡烛的制作
6.蜡烛的净化，祝福
7.符号含义
8.给蜡烛刻符号
9.完整仪式流程介绍

<h2>第五节课（感情类仪式）</h2>

1.斩桃花仪式
2.驱逐第三者仪式
3.赢得某人的爱仪式
4.招桃花仪式
5.甜蜜蜜仪式
6.复合仪式
7.治愈不幸的婚姻仪式
8.爱情绑定仪式
9.忘掉某人仪式
10.快速分手仪式

<h2>第六节课（财富类，事业，学业仪式）</h2>

1.获得金钱仪式
2.解除经济危机仪式
3.快速得到金钱仪式
4.创造机会，得到金钱仪式
5.稳定工作仪式
6.学业仪式

<h2>第七节课（健康，保护）</h2>

1.戒掉坏习惯仪式
2.身体健康仪式
3.危机得到保护仪式
4.旅行得到保护仪式
5.打破诅咒仪式
6.让恶魔远离仪式
7.小五芒星驱逐仪式

<h2>第八节课（祝福）</h2>

1.祝福自己，他人仪式
2.出生祝福仪式
3.祝福亡灵仪式
4.怀孕祝福仪式

<h2>第九节课（美容类）</h2>

1.减肥仪式
2.美白仪式
3.美容仪式
4.头发生长仪式
5.魔鬼身材仪式
6.增加个人魅力仪式

<h2>第十节课（魔法草药）</h2>

1.草药魔法
2.几十种魔法草药的含义

<h2>第十一节课（魔法油）</h2>

1.魔法油的生效原理
2.魔法喷雾的制作
3.20种魔法油的制作
4.十二星宫强化油制作
5.魔法油的加持，显化

<h2>第十二节课（魔法周边）</h2>

1.魔法蜡烛的制作
2.魔法口红的制作
3.魔法膏药，草药罐子
4.魔法香水的制作
5.巫毒娃娃制作
6.巫术袋，草药袋的制作
7.魔法茶的制作
8.魔法浴盐的制作
9.魔法粉的制作
10.龙血墨的制作

<h2>第十三节课（待补充）</h2>
`,
};
