<template>
  <div class="memberShipList">
    <div class="memberWrap" v-for="(group, index) in peopleList" :key="index">
      <div class="title">
        <img :src="group.img" alt="" />
        <span>{{ group.title }}</span>
      </div>
      <ul v-for="(item, i) in group.peopleList" :key="i">
        <li v-for="people in item" :key="people">
          {{ people }}
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import Api from "@/api";
export default {
  data() {
    return {
      peopleList: [
        {
          title: "专业会员",
          img: require("@/assets/images/memberShip/memberProfessional.png"),
          peopleList: [],
        },
        {
          title: "联盟会员",
          img: require("@/assets/images/memberShip/memberLego.png"),
          peopleList: [],
        },
      ],
    };
  },
  mounted() {
    this.getMember();
  },
  methods: {
    async getMember() {
      try {
        const { code, data } = await Api.memberwall({ userName: "" });
        if (code === 0 && data && data.length > 0) {
          this.peopleList[0]["peopleList"] = [];
          this.peopleList[1]["peopleList"] = [];
          data.forEach((item) => {
            if (item.accountRole === 1) {
              //2 专业会员 1联盟会员
              this.peopleList[0]["peopleList"].push(item.userName);
            } else {
              this.peopleList[1]["peopleList"].push(item.userName);
            }
          });
          console.log("this.peopleList", this.peopleList);
          this.peopleList[0]["peopleList"] = this.chunkArray(
            this.peopleList[0]["peopleList"],
            4
          );
          this.peopleList[1]["peopleList"] = this.chunkArray(
            this.peopleList[1]["peopleList"],
            4
          );
        }
      } catch (e) {
        this.$message.error(e);
      }
    },
    chunkArray(array, chunkSize) {
      let result = [];
      for (let i = 0; i < array.length; i += chunkSize) {
        result.push(array.slice(i, i + chunkSize));
      }
      return result;
    },
  },
};
</script>
<style lang="less" scoped>
.memberShipList {
  padding-bottom: 100px;
  .memberWrap {
    margin-bottom: 20px;
    .title {
      text-align: center;
      font-size: 18px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      color: #08101e;
      line-height: 44px;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 20px;
        margin-right: 5px;
      }
    }
    ul {
      border-bottom: 1px solid #d9ad6a;
      display: flex;
      margin: 0 16.7%;
      line-height: 64px;
      flex-wrap: wrap;
      li {
        width: 24%;
        font-size: 16px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #d9ad6a;
        line-height: 44px;
        text-align: center;
      }
    }
  }
}
</style>
