export default {
  img: require("@/assets/images/banner.png"),
  banner: require("@/assets/images/banner.png"),
  title: "臼井圣火灵气1-2-3阶",
  introduction: "这门课程需要您有开阔的胸怀和爱,会学习如何疗愈和水晶阵成愿。",
  id: "course_reiki_1_2_3",
  auth: "华人灵气研究协会",
  type: "ccourse",
  relate: ["course_"],
  time: "2014-01-01",
  reikiEffect: [
    "",
    "",
    "",
    "",
    "",
    "",
  ],
  content: `
<h2>臼井灵气 I 阶 （ 西藏臼井+臼井圣火）</h2>

<p class='Gray'>颁发ICRT国际认证&华人灵气协会认证双证书</p>
<p>灵气一阶主要会学习灵气的理论基础知识以及学习如何进行面对面自我疗愈&他人疗愈、学习气场清理等</p>

课程内容

1.净化自身&空间（气场清理）
2.介绍什么是灵气，灵气不同的等级
3.灵气是如何运作，灵气的历史
4.灵气的流派
5.介绍Gassho冥想（合掌冥想），
6.灵气技巧学习 （3大支柱和其他技巧）
7.灵气五戒
8.三重天堂
9.圣爱海洋体验，灵气 I 植入（点化）
10.练习灵气（彼此练习，导师指导）
11.气场扫描和感知他人的气场
12.用灵气 I 给自己和他人疗愈的全套流程
13.体验传递臼井灵气点化I
14.更多...


<h2>臼井灵气 2 阶 （ 西藏臼井+臼井圣火）</h2>

<p>灵气二阶主要会学习三个灵气符号以及学习如何进行远程疗愈、学习灵气技巧等</p>

课程内容

1.净化自身&空间（气场清理）
2.灵气 II 植入（点化），圣爱体验
3.介绍远程灵气符号的使用
4.学习用眼睛传递灵气
5.介绍根据不同部位病痛使用灵气疗愈
6.介绍针对减肥，吸毒，酗酒等情况方案
7.介绍力量符号使用方法
8.介绍情绪(心灵)符号使用方法
9.介绍远距离符号使用方法
10.体验传递的臼井灵气点化II
11.介绍进行一对多和多对一的集体灵气方法
12.介绍完整的远程灵气的疗愈方法（如果用小熊代替，用小卡片代替等方法）
13.和学生一起练习，互相给予灵气
14.介绍灵气道德和实践标准
15.更多...


<h2>臼井灵气 3 阶（ 西藏臼井+臼井圣火）</h2>

<p>灵气三阶主要会学习灵气的大师符号和圣火符号，学习如何运用水晶阵等内容</p>

课程介绍

1.净化自身&空间（气场清理）
2.灵气 III 植入（点化），生命之河疗愈体验
3.体验传递的臼井灵气点化III
4.介绍臼井大光明符号
5.介绍圣火符号使用方法
6.介绍一些灵气技巧使用方法
7.如何形成自己的灵气风格
8.灵气冥想操
9.完整的灵气疗愈过程
10.教学世界和平阵，水晶阵成愿系列内容.
11.颁发ICRT国际认证证书
12.更多...
`,
};