export default {
  img: require("@/assets/images/banner.png"),
  banner: require("@/assets/images/banner.png"),
  title: "水晶灵气课",
  introduction: "通过将水晶的强大振动与灵气能量想结合，可以协助和改善我们生命中物质的不平衡以及精神/情感的混乱。",
  id: "course_crystal",
  auth: "华人灵气研究协会",
  type: "ccourse",
  relate: ["course_crystal"],
  time: "2014-01-01",
  reikiEffect: [
    "与水晶进行链接, 感知水晶能量",
    "水晶与灵气结合可以放大灵气能量",
    "获得天使的祝福",
    "改善感情/事业/学业/财富/灵性等主题",
    "减轻积存身体的负面能量",
    "改善睡眠质量、减轻神经紧张",
    "减轻内心痛楚、烦躁不安、恐惧感",
    "增强自觉、直觉与内在洞察力",
    "促进个人成长、提升灵性修为",
  ],
  content: `
  1.水晶作为治疗和提高意识水平的工具已经被使用了几个世纪，每一块水晶都有特定的频率，属性，以及疗愈属性。
  
  水晶灵气利用了驻留在地球内部的水晶频率，并通过灵气的宇宙生命能量放大它们。

  通过将水晶的强大振动与灵气能量想结合，可以协助和改善我们生命中物质的不平衡以及精神/情感的混乱。
  
<h2>课程介绍</h2>

1.如何挑选水晶 - 水晶内部结构，外部结构，颜色- 色彩年轮，辨别真假等
2.如何存储水晶
3.水晶净化方式- 为什么净化，什么时候净化，常见方式
4.水晶充电与编程，反编程
5.什么是水晶灵气
6.水晶灵气的历史以及优势
7.水晶灵气疗愈流程
8.场景疗愈 - 水晶灵气脉轮疗愈
9.场景疗愈 - 水晶灵气疗愈动物
10.场景疗愈 - 水晶灵气与居家风水 【财富，声誉，感情，家庭，健康，心理】
11.更多...

<h2>上课形式</h2>

1.注：知识点部分会提供录播，可反复听
1.点化部分
2.点化方式：腾讯会议/Zoom ，实时参与
            如果不可以实时参与，需提供个人相关信息
3.相关信息：姓名，出生日期年月日或者照片       
4.课程价格：688元
5.早鸟价：499元
6.复训价格：99元
7.课程时长：120分钟 [包括点化时间，重点内容介绍,不包括录播内容时间]
8.下期课程时间：咨询客服
`,
};